:root {
  --rsbs-max-w: 96%;
  --rsbs-ml: auto;
  --rsbs-mr: auto;
  --rsbs-overlay-rounded: 146px;
}

.kata {
  transition: all 0.5s;
}
.trans {
  transition: all 0.5s;
}
* {
  font-family: "Poppins", sans-serif;
}

.hover-blue:hover {
  background-color: #0062ff !important;
  border: 0px !important;
  color: #ffffff !important;
}

.hover-red:hover {
  background-color: rgb(241, 109, 109) !important;
  border: 0px !important;
  color: #ffffff !important;
}

button {
  outline: none !important;
}

.elipsis {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  width: 100%;
  align-self: flex-start;
  justify-self: start;
  text-align: start;
  margin: 0;
}
.elipsisSidebar {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  width: 30px;
  align-self: flex-start;
  justify-self: start;
  text-align: start;
  margin: 0;
}

.myShadow {
  -webkit-box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.37);
  box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.37);
}
.myShadowLight {
  -webkit-box-shadow: -8px 8px 55px rgba(17, 17, 17, 0.1);
  box-shadow: -8px 8px 55px rgba(17, 17, 17, 0.1);
}
.mySmallShadow {
  -webkit-box-shadow: 0px 0px 5px 0px rgba(84, 78, 78, 0.37);
  box-shadow: 0px 0px 5px 0px rgba(84, 78, 78, 0.37);
}
.mySmallDarkShadow {
  -webkit-box-shadow: -1px 8px 15px rgba(25, 25, 25, 0.37);
  box-shadow: -1px 8px 15px rgba(29, 28, 28, 0.37);
}

.noScroll::-webkit-scrollbar {
  display: none;
}

.scrollbar::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.scrollbar::-webkit-scrollbar-track {
  border-radius: 100vh;
  background: var(--bg-settings);
  /* background: #f7f4ed; */
}

.scrollbar::-webkit-scrollbar-thumb {
  background: #0062ffb6;
  border-radius: 100vh;
  border: 1px solid var(--secondary-gray);
  /* border: 2px solid #f6f7ed; */
}

.scrollbar::-webkit-scrollbar-thumb:hover {
  background: #0062ff;
}

.custom-tooltip {
  display: flex;
  background-color: white;
  border: 3px solid #f6f7ed;
  border-radius: 6px;
  flex-direction: column;
  padding: 10px;
}
.label-container {
  display: flex;
}
.label-value {
  font-size: 12px;
  font-weight: 400;
  margin: 0;
  margin-bottom: 5px;
}
.label {
  font-size: 13px;
  font-weight: 500;
  text-transform: capitalize;
  margin: 0;
  margin-bottom: 5px;
}
.custom-tooltip-intro-primary {
  font-size: 12px;
  font-weight: 500;
  text-transform: capitalize;
  margin: 0;
  margin-bottom: 5px;
  color: #0062ff;
}
.custom-tooltip-intro-secondary {
  font-size: 12px;
  font-weight: 500;
  text-transform: capitalize;
  margin: 0;
  color: #9da6b7;
  margin-bottom: 5px;
}
.chart-primary-color {
  color: #0062ff;
}
.chart-secondary-color {
  color: #9da6b7;
}

.content-space-between {
  justify-content: space-between;
}

/* 
.divdiv {
	
	display: flex;
	align-items: center;
	justify-content: center;
}

.labellabel {
	position: relative;
	display: block;
	width: 80px;
	height: 20px;
	border-radius: 100px;
	background-color: #000839;
	overflow: hidden;
	cursor: pointer;
	&:before,
	&:after {
		display: block;
		position: absolute;
		content: "";		
		width: 72px;
		height: 72px;
		border-radius: 50%;
		top: 14px;
		left: 14px;
		transition: .5s ease;
	}
	&:before {
		background-color: #ffa41b;

	}

	&:after {
		background-color: #000839;
		left: -58px;
		transform: scale(0.00001);
	}
}

.inputinput[type="checkbox"] {
	display: none;
	&:checked + label {
		&:before {
			background-color: #FFF;
			transform: translateX(80px);
		}
		
		&:after {
			transform: translateX(120px) scale(1);
		}
	}
} */

.dot {
  transition: opacity 0.25s ease-in;
  /* opacity: 0.3; */
  filter: alpha(opacity=30);
  /* box-shadow: 1px 1px 2px rgb(0 0 0 / 90%); */
  background: white;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  cursor: pointer;
  display: inline-block;
  margin: 0 8px;
  border: 2px solid #0062ff;
}

.primaryButton:hover {
  background-color: #0f3cd9;
}

.primaryButton:focus {
  box-shadow:
    0px 0px 0px 3px #e8edff,
    0px 1px 2px 0px #e8edff;
}
.secondaryButton:hover {
  background-color: #f9fafb;
}

.secondaryButton:focus {
  box-shadow:
    0px 0px 0px 3px #f0f3f9,
    0px 1px 2px 0px #f0f3f9;
}

.recharts-tooltip-wrapper {
  border: 0px solid transparent !important;
}
