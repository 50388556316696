.dropDownComponent {
  width: 100% !important;
  margin-right: 0px !important;
}
.dropdownInput {
  border: 1px solid #cce0ff !important;
}
.iconCont {
  padding: 4px;
  border-radius: 4px;
  background-color: #cce0ff;
  margin-right: 6px;
}
.dropdownContainer {
  border-width: 0px 1px 1px 1px;
  width: 100%;
  border-radius: 0px 0px 10px 10px;
  color: var(--primary-gray);
  background-color: var(--white);
  font-size: var(--font-size-14);
  position: absolute;
  z-index: 999;
  max-height: 300px;
  overflow-y: scroll;
  border: 1px solid #cce0ff !important;
  border-top: 0px !important;
}
.oneItem {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 16px;
}
.oneItemInDropdown {
  padding: 10px 20px !important;
  cursor: pointer;
  text-transform: capitalize;
  width: 100%;
}

.dividerText {
  font-weight: 500;
  color: var(--font-dark);
  font-size: 16px;
  margin-bottom: 10px;
}

.disabledDropdown {
  opacity: 0.5;
}

.trashIcon {
  color: var(--font-dark);
}
