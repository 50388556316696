.container {
  position: relative;
  margin-right: 16px;
}

.buttonContainer {
  border: 1px solid var(--secondary-gray);
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 15px 10px 10px;
  font-size: 13px;
  color: var(--primary-gray);
  cursor: pointer;
  text-wrap: nowrap;
}

.categorie {
  display: flex;
  border-left: 1px solid var(--secondary-gray);
  margin-left: 6px;
  padding-left: 6px;
  align-items: center;
}

.count {
  height: 18px;
  width: 24px;
  border-radius: 20px;
  background-color: #0062ff;
  text-align: center;
  margin-right: 4px;
  color: white;
  font-size: 12px;
}
.catName {
  color: #0062ff;
  text-transform: capitalize;
}

.filterDropdown {
  position: absolute;
  top: 44px;
  left: 0px;
  width: 200px;
  background-color: var(--white);
  border-radius: 6px;
  border: 1px solid var(--secondary-gray);
  box-shadow: -1px 39px 112px -13px rgba(0, 0, 0, 0.3);
  padding: 12px 0px;
  width: 300px;
  z-index: 999;
}

.dropdownTitle {
  border-bottom: 1px solid var(--secondary-gray);
  padding-bottom: 12px;
  padding-left: 20px;
  display: flex;
  align-items: center;
  font-size: 13px;
  color: var(--primary-gray);
  font-weight: 500;
}

.subDropdown {
  transition: height 0.3s ease;
  overflow: hidden;
  color: var(--primary-gray);
}

.subDropdownCont {
  font-size: 14px;
  padding: 12px 16px 0px 20px;
  color: var(--font-dark);
}

.countCont {
  height: 20px;
  width: 20px;
  background-color: #f0f0f0;
  border-radius: 99px;
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #92929d;
  /* color: var(--font-dark); */
}

.activeSubHeight {
  height: 320px;
  transition: height 0.3s ease;
}

.canceledSubHeight {
  height: 250px;
  transition: height 0.3s ease;
}

.completedSubHeight {
  height: 70px;
  transition: height 0.3s ease;
}
.isClosed {
  height: 0px;
}
