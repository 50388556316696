.container {
  background-color: rgba(0, 0, 0, 0.564);
  backdrop-filter: blur(5px);
}

.close {
  background-color: rgba(74, 74, 74, 0.412);
  border-radius: 100px;
}

.close:hover {
  background-color: rgb(74, 74, 74);

}