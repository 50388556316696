.container {
  width: 100%;
  border-radius: 8px;
  background-color: var(--bg-dark-secondary);
  padding: 20px 20px;
  margin-bottom: 0px;
}

.hlfSide {
  padding: 16px 8px 10px 16px;
  width: 100%;
  background-color: var(--white);
  height: 100%;
}

.svgBg {
  height: 45px;
  width: 45px;
  background-color: var(--lighter-gray);
  display: flex;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  margin-left: -3px;
}

.title {
  font-size: mdfontsize;
  text-transform: capitalize;
  color: var(--font-dark);
}

.errorText {
  color: #ff3f3f;
  font-size: var(--font-size-12);
  padding: 6px 10px 0px 12px;
}

.fontLight {
  color: var(--primary-gray);
}

.addressText {
  color: var(--font-dark);
  font-size: var(--font-size-16);
}
