.addPaymentMethodButton {
  width: 20%;
  color: white;
}
.disabled {
  opacity: 0.5;
}

.container {
  height: calc(100vh - 70px);
  position: relative;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.heading {
  font-size: 24px;
  font-weight: 600;
  color: var(--font-dark);
}

.button {
  font-size: 14px;
  padding: 5px 10px;
  height: 38px;
  border-color: var(--primary-blue);
  border-radius: 6px;
}

.uploadButton {
  border-radius: 6px;
  background: var(--lighter-gray);
  border-color: var(--lighter-gray);
}

.buttonContent {
  display: flex;
  gap: 10px;
  align-items: center;
}

.uploadButtonText {
  color: var(--dark-gray);

  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.addPaymentText {
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.table {
  min-width: 100%;
  height: calc(100vh - 255px);
}

.tableBody {
  overflow-y: scroll;
  height: calc(100% - 78px);
  table-layout: fixed;
  display: block;
}

.tableContainer {
  margin-top: 20px;
  border: 1px solid var(--table-border-gray);
  border-radius: 20px;
}

.tableFooter {
  border-top: 1px solid var(--table-border-gray);
}

.tableHeader {
  height: 80px;
  display: table;
  width: 100%;
  table-layout: fixed;
  border-bottom: 1px solid var(--table-border-gray);
}

@media (max-width: 640px) {
  .table {
    overflow-x: scroll;
  }

  .tableHeader {
    overflow-x: scroll;
  }
}

@media (max-width: 1224px) {
  .table {
    min-width: 100%;
    height: calc(100vh - 400px);
  }

  .tableBody {
    height: calc(100% - 80px);
  }
}

.paymentButtons {
  display: flex;
  gap: 4px;
}
