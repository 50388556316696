.visibleOnDesktop {
  display: block;
}
.visibleOnMobile {
  display: none;
}

@media (max-width: 1223px) {
  .visibleOnDesktop {
    display: none;
  }
  .visibleOnMobile {
    display: block;
  }
}
