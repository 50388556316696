.container {
  z-index: 1000;
  position: relative;
  min-width: 138px;
  outline: none;
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border: 2px solid var(--secondary-gray);
  border-radius: 10px;
  padding: 8px 16px;
  margin-top: 12px;
  box-shadow: 0px 30px 100px -13px rgba(0, 0, 0, 0.3);
}

.actionItem {
  font-size: 16px;
  font-weight: 500;
  width: calc(100% + 32px);
  padding: 8px 0 8px 16px;
  text-align: start;
  color: var(--primary-gray);
  margin-left: -16px;
}

@media screen and (max-width: 1223px) {
  .actionItem {
    padding: 18px 24px;
  }
}
