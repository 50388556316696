.bellOnHeader {
  border-radius: 10px;
  padding: 7px 26px 7px 18px;
  position: relative;
  display: flex;
  align-items: center;
  font-size: var(--font-size-16);
  color: var(--font-dark);
}
.dropdown {
  width: 170px;
}
.active {
  opacity: 0.5;
}

.orderIdTitle {
  color: var(--font-dark);
}
.oneNumber {
  display: flex;
  align-items: center;
  color: var(--font-dark);
}
.light {
  color: var(--primary-gray);
  margin-left: 4px;
}
.phoneIcon {
  flex-shrink: 0;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--primary-blue);
  font-size: 16px;
  padding-left: 1px;
  margin-right: 8px;
}

.labels {
  /* display: block; */
  border-radius: 10px;
  padding: 0px 10px;
  background-color: #bc83f6;
  text-align: start;
  justify-self: center;
  font-size: var(--font-size-12);
  color: white;
  margin-bottom: 10px;
}

.bgForCustomer {
  background-color: #31d171 !important;
}

.copyIcon {
  margin-left: 0;
  cursor: pointer;
}