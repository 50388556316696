.buttonContainer {
  font-size: var(--font-size-12);
  font-weight: 500;
  padding: 5px 10px;
  background-color: var(--primary-blue);
  color: white;
  border-radius: 10px;
}

.iconColor {
  color: white;
}
