.container {
  margin-top: 20px;
}

.historyHeaderWrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 17px;
  margin-top: 11px;
  gap: 5px;
}
.dividerLine {
  width: calc(100% + 48px);
  height: 1px;
  background-color: #ececec;
  margin-left: -24px;
  margin-right: -24px;
}
.heading {
  font-size: 24px;
  font-weight: 600;
  color: var(--font-dark);
}

.backButton {
  color: #808080;
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 153.846% */
  cursor: pointer;
}

.table {
  min-width: 100%;
  height: calc(100vh - 255px);
}

.tableBody {
  overflow-y: scroll;
  height: calc(100% - 78px);
  table-layout: fixed;
  display: block;
}

.tableContainer {
  border: 1px solid var(--table-border-gray);
  border-radius: 20px;
}

.tableFooter {
  border-top: 1px solid var(--table-border-gray);
}

.tableHeader {
  height: 80px;
  display: table;
  width: 100%;
  table-layout: fixed;
  border-bottom: 1px solid var(--table-border-gray);
}

@media (max-width: 640px) {
  .table {
    overflow-x: scroll;
  }

  .tableHeader {
    overflow-x: scroll;
  }
}

@media (max-width: 1224px) {
  .table {
    min-width: 100%;
    height: calc(100vh - 400px);
  }

  .tableBody {
    height: calc(100% - 80px);
  }
}
