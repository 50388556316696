.providersTooltipContainer {
  /* width: 300px */
  background-color: var(--white);
  box-shadow: -1px 39px 112px -13px rgba(0, 0, 0, 0.3);
  border: 1px solid #edf2f7 !important;
  padding: 10px;
  border-radius: 10px;
}

.oneProviderOnTooltip {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
}

.square {
  height: 12px;
  width: 12px;
  border-radius: 2px;
  margin-right: 5px;
}

.nameOnTooltip {
  text-transform: capitalize;
  color: var(--font-dark);
}
.value {
  color: var(--primary-gray);
}

.nameCont {
  display: flex;
  align-items: center;
  max-width: 240px;
  margin: 4px 0px;
  margin-right: 30px;
}
