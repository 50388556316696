.container {
  font-family: "Poppins", sans-serif;
  background-color: var(--white);
  border-radius: 8px;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 0px;
  flex-wrap: wrap;
  flex-flow: row wrap;
  box-sizing: border-box;
  padding: 20px 20px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

.selectUserText {
  font-size: var(--font-size-16);
  font-weight: 400;
  color: var(--font-dark);
}

.searchCont {
  width: 100%;
  border-width: 1px;
  border-radius: 6px;
  border-color: var(--secondary-gray);
  padding: 10px 10px;
  display: flex;
  align-items: center;
  justify-content: start;
  background-color: var(--bg-settings);
  margin-top: 10px;
}

.searchInput {
  outline: none;
  width: 100%;
  height: 100%;
  margin-left: 10px;
  background-color: transparent;
  color: var(--font-dark);
}

.listCont {
  width: 100%;
  max-height: 43vh;
  background-color: var(--lighter-gray);
  margin-top: 30px;
  overflow-y: auto;
}

.imageCont {
  height: 40px;
  width: 40px;
  border-radius: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 10px;
}

.name {
  font-size: var(--font-size-14);
  margin-left: 10px;
  min-width: 34%;
  display: flex;
  justify-content: start;
  font-weight: 500;
}
