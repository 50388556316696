.requestAccessButtom {
    color: var(--primary-blue);
    background-color: #E6F0FF;
    border: none;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 4px 8px;
    border-radius: 6px;
    font-style: normal;
    font-weight: 500;
    font-size: var(--font-size-12);
    line-height: 20px;
    text-transform: capitalize;
}

.badge {
    width: fit-content;
}
