.container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 26px;
}
.headingText {
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: var(--font-dark);
}
.compByText {
  color: var(--primary-gray);
  font-size: 14px;
  margin-right: 4px;
}
.conparedToText {
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  color: var(--primary-gray);
  margin: 0 12px;
}
.providersDropdown {
  /* margin-right: 10px; */
  background-color: var(--secondary-background);
  border-radius: 20px;
  padding: 8px 20px;
  font-size: var(--font-size-14);
  cursor: pointer;
  display: flex;
  align-items: center;
}

.oneSide {
  display: flex;
  align-items: center;
  padding: 4px 10px !important;
  font-size: 13px !important;
  color: var(--font-dark);
  border-radius: 100px !important;
}
.activeButton {
  /* background-color: var(--light-gray); */
  background-color: var(--white);
  opacity: 1;
}
.cooseCont {
  display: flex;
  align-items: center;
  background-color: var(--secondary-background);
  /* background-color: var(--lighter-gray); */
  border: 1px solid var(--lighter-gray);
  padding: 4px 6px;
  border-radius: 20px;
  position: relative;
}
.copareItem {
  width: 100px;
  text-align: center;
  border-radius: 10px;
  background-color: var(--white);
  cursor: pointer;
}
.dropDownComponent {
  width: 160px !important;
  margin-right: 0px !important;
}

.dropdownContainer {
  border-width: 1px 1px 1px 1px;
  border-radius: 0px 0px 10px 10px;
  color: var(--primary-gray);
  background-color: var(--white);
  font-size: var(--font-size-14);
  position: absolute;
  /* left: 0px; */
  top: 40px;
  z-index: 999;
  max-height: 340px;
  overflow-y: auto;
  border-radius: 6px;
  box-shadow: -1px 39px 112px -13px rgba(0, 0, 0, 0.3);
  padding: 14px 0px;
}

.left0 {
  left:10px;
}

.minusLeft20 {
  left: -0px;
}

.disabledDropdown {
  opacity: 0.5;
}

.oneItemInDropdown {
  cursor: pointer;
  text-transform: capitalize;
  width: 100%;
  font-size: 13px;
  text-wrap: nowrap;
}

.oneItem {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 16px;
  padding: 6px 20px;
  cursor: pointer;
}
.icons {
  padding: 0px 10px 0px 0px;
}

.filtersBg {
  background-color: var(--secondary-background);
}
