.tabButton {
    width: 33.33%;
}

.tabLine {
    width: 100%;
}

.tabs {
    gap: 0;
}

.container {
    padding: 24px;
    background-color: var(--white);
    height: 100%;
}

.closeIcon {
    padding: 3px;
    border: 1px solid var(--primary-gray);
    border-radius: 100%;
    cursor: pointer;
}

.drawerHeader {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.drawerTitleSection {
    width: 100%;
    text-transform: capitalize;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-weight: 500;
    font-size: 26px;
    color: var(--font-dark);
}
