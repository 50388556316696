.container {
  padding: 20px 10px;
  border-color: var(--warning-grey);
}

.providerName {
  font-size: var(--font-size-16);
  font-weight: 500;
  color: var(--font-dark);
  /* margin-left: 10px; */
}

.reasonOrTime {
  margin-top: 4px;
  color: var(--primary-gray);
  font-size: var(--font-size-12);
}

.fee {
  color: var(--font-dark);
  font-weight: 600;
  text-align: right;
}

.quoteTitleSection {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.timeSection {
  width: 100%;
  padding-left: 100px;
  margin-top: -20px;
}

