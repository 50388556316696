.title {
    font-weight: 600;
    font-size: var(--font-size-20);
    margin-top: 30px;
    color: var(--font-dark);
}

.description {
    font-size: var(--font-size-14);
    font-weight: 400;
    line-height: 21px;
    color: var(--primary-gray);
    margin: 8px 0;
}

.pageBody {
    height: calc(100vh - 70px);
    width: 100%;
    padding: 0 30px 100px 30px;
    position: relative;
    border-top: 1px solid #f2f2f7;
    overflow-y: scroll;
}

.testModeBadge {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    background-color: var(--primary-orange);
    color: #fff;
    padding: 0 10px;
    border-radius: 0 0 8px 8px;
    font-size: var(--font-size-14);
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0;
}

.requestProviderButton {
    margin-top: 10px;
}
