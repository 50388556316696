.cardsGrid {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
    gap: 1.4%;
    row-gap: 18px;
}

.loader {
    height: 50vh;
    width: 100%;
}

.noProvidersText {
    color: var(--font-dark);
}
