.expandCircle {
  box-shadow: 0px 4px 15.2px rgba(0, 0, 0, 0.15);
  width: 24px;
  height: 24px;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 33px;
  top: 43%;
  z-index: 999;
  background-color: var(--white);
  cursor: pointer;
}

.expandIcon {
  font-size: 14px;
  color: var(--primary-gray);
}
