.menu {
  display: flex;
  align-items: center;
  gap: 6px;
  background-color: #0066ff;
  padding: 16px;
  border-radius: 25px;
  position: relative;
}

.dot {
  width: 6px;
  height: 6px;
  background-color: #a5c5ff;
  border-radius: 50%;
  cursor: pointer;
}

.dot.active {
  width: 30px;
  height: 6px;
  background-color: white;
  border-radius: 10px;
  left: calc(8px + (var(--active-index) * 20px));
  cursor: default;
}
