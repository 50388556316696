.notificationsHeader {
  width: 100%;
  height: 60px;
  padding: 0px 20px;
  border-bottom: 1px solid var(--secondary-gray);
  color: var(--font-dark);
}
.title {
  font-size: var(--font-size-18);
  font-weight: 600;
}

.markAsRead {
  font-size: var(--font-size-14);
  font-weight: 500;
  color: var(--primary-blue);
  margin-right: 6px;
}
.settingsIcon {
  color: var(--primary-gray);
}
.menuContainer {
  background-color: var(--white);
  width: 570px;
  right: 30px;
  max-height: 670px;
  border: 1px solid var(--secondary-gray);
  box-shadow: -1px 39px 112px -13px rgba(0, 0, 0, 0.25);
  padding: 20px 0px;
}

.scrollableCont {
  overflow-y: auto;
  max-height: 490px;
  padding: 10px 20px;
}
.emptyState {
  height: 420px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loadingCont {
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.date {
  font-size: var(--font-size-15);
  font-weight: 600;
  margin: 8px 0px 12px 0px;
  color: var(--font-dark);
}
.created_at {
  font-size: var(--font-size-15);
  font-weight: 600;
  margin: 8px 0px 12px 0px;
  color: var(--font-dark);
}

.oneNotification {
  padding: 10px 0px;
  text-align: left;
  font-size: var(--font-size-14);
  border-bottom: 1px solid var(--secondary-gray);
  margin-bottom: 8px;
}

.active {
  opacity: 0.5;
}

.orderIdTitle {
  color: var(--primary-gray);
}
.orderId {
  color: var(--font-dark);
}

.desc {
  color: var(--primary-gray);
  font-size: var(--font-size-14);
}
.timeInNotif {
  font-size: var(--font-size-12);
  color: var(--primary-gray);
}

.unreadIcon {
  color: var(--primary-blue);
}
.readIcon {
  color: var(--primary-gray);
}
.readCircle {
  border-radius: 22px;
  width: 22px;
  height: 22px;
  border-width: 2px;
  border-color: var(--secondary-gray);
}
.unreadBigCirlce {
  border-radius: 22px;
  width: 24px;
  height: 24px;
  background-color: #d0e5f6;
  display: flex;
  justify-content: center;
  align-items: center;
}
.unreadSmallCirlce {
  border-radius: 22px;
  width: 12px;
  height: 12px;
  background-color: var(--primary-blue);
}
