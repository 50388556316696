.container {
  height: 65px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border: 1px solid var(--brand-blue-800);
  margin-top: 10px;
  border-radius: 14px;
  padding: 13px;
}

.borderAttemptingReroute {
  border-color: #6B6B6B
}

.bannerLeftSection {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
}

.bannerLeftContent {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.bannerRightSection {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.titleText {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 16.8px;
  text-align: left;
  color: var(--black);
}

.reasonText {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  color: var(--gray-text)
}

.hyperlink {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  text-align: left;
}

.deliveryLink {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  color: var(--gray-text)
}
