.button {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  /* font-size: var(--font-size-28); */
  font-size: var(--font-size-14);
  justify-content: center;
  align-items: center;
  outline: none;
  transition: all 0.5s;
  color: var(--primary-gray);
}

.textPrimary {
  color: var(--primary-blue);
}

.bottomLine {
  width: 80%;
  height: 4px;
  border-radius: 0px 0px 4px 4px;
  background-color: "transparent";
}

.bottomLineActive {
  background-color: var(--primary-blue);
}
