@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-top: 1px solid #dcdcdc;
  padding-top: 30px;
}

.buttonStyles {
  width: 280px;
  height: 44px;
  color: white;
  text-align: center;
  font-size: 16px !important;
  font-weight: 500;
  font-family: "Inter", sans-serif !important;
}

.hyperLink {
  color: var(--primary-blue);
  margin-left: 6px;
  margin-right: 2px;
  cursor: pointer;
}

.footerText {
  color: #000;
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
