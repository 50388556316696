.logoContainer {
  height: 160px;
  width: 160px;
  border-radius: 200px;
  overflow: hidden;
  margin-bottom: -80px;
  z-index: 999;
}
.logoContainerDesktop {
  height: 100px;
  width: 100px;
  border-radius: 200px;
  overflow: hidden;
  margin-bottom: -50px;
  margin-top: 90px;
  z-index: 999;
  background-color: var(--white);
  box-shadow: 5px 5px 5px var(--warning-grey);
}

.contentCont {
  background-color: var(--white);
  border-radius: 20px;
  padding-top: 90px;
  padding-bottom: 30px;
}
.contentContDesktop {
  background-color: var(--white);
  border-radius: 20px;
  padding: 25px 0px;
}

.providerName {
  color: var(--font-dark);
  font-weight: 500;
}

.ratingEmojies {
  height: 150px;
  width: 150px;
  padding: 2px;
  border-radius: 300px;
  object-fit: fill;
  cursor: pointer;
}
.ratingEmojiesDesktop {
  height: 70px;
  width: 70px;
  padding: 2px;
  border-radius: 300px;
  object-fit: fill;
  cursor: pointer;
}

.submitButton {
  border-radius: 50px !important;
  margin-top: 60px;
  padding: 16px 20% !important;
}

.textInput {
  border-radius: 8px;
  font-size: var(--font-size-16);
  color: var(--font-dark);
  padding: 10px 20px;

  outline: none;
  background-color: var(--secondary-gray);
  resize: none;
  max-height: 300px;
  margin-top: 30px;
}
