.container {
    width: 100%;
    padding-left: 100px;
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header {
    margin-top: 10px;
    color: var(--font-dark);
    font-size: var(--font-size-14);
}
.subtext {
    /* margin-top: 10px; */
    color: var(--primary-gray);
    font-size: var(--font-size-12);
    font-weight: 300;
}

.note {
    color: var(--primary-gray);
    font-size: var(--font-size-12);
}

.feeNote {
    color: #ff974a;
    font-weight: 600;
    font-size: var(--font-size-14);
}
