.container {
    display: flex;
    justify-content: center; 
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    border-radius: 0.375rem;
    overflow: hidden;
    font-size: var(--font-size-14);
    padding-bottom: "10px";
    background-color: var(--white);
}

.badge {
    font-size: var(--font-size-14);
}
