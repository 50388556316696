.font_14 {
  font-size: var(--font-size-14);
}

.textGrey {
  color: var(--primary-gray);
}

.gradient {
  background: linear-gradient(to right, var(--primary-blue) 20%, var(--white) 65%);
}

.ratingNumber {
  font-size: var(--font-size-14);
  color: var(--primary-gray);
  font-weight: 500;
  margin: 0px 4px;
}

.tooltip {
  width: 350px;
  max-height: 300px;
  left: -154px;
  top: 30px;
  padding-top: 10px;
  z-index: 99;
  border-radius: 10px;
}

.tooltipAngle {
  left: 153px;
  top: 0px;
  rotate: 45deg;
  background-color: var(--sky-blue);
}

.toolTipContent {
  overflow-y: auto;
  background-color: var(--sky-blue);
  color: var(--font-dark);
  padding: 23px 15px 15px 15px;
  border-radius: 10px;
  z-index: 999;
  user-select: text;
  border: 1px solid var(--secondary-gray);
}

.square {
  height: 20px;
  width: 20px;
  background-color: var(--primary-blue);
  border-radius: 4px;
  margin: 0px 5px 0px 0px;
}
