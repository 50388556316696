.container {
  display: flex;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
}
.contentContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1280px;
  max-height: 92%;
  height: 100%;
  width: 100%;
  justify-content: space-between;
  background-color: white;
  /* overflow-y: auto; */
  border-radius: 10px;
  box-shadow: 0px 6px 30px 0px rgba(0, 0, 0, 0.25);
  /* padding: 25px 45px; */
}

.background {
  background-image: url("../../../assets/png/onboarding/screenshot.png");
  background-size: cover;
  background-position: center;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  /* filter: blur(10px); */
}

@media (max-width: 1023px) {
  .contentContainer {
    max-height: 100%;
  }
}

@media (max-width: 1323px) {
  .contentContainer {
    max-height: 100%;
  }
}
@media (min-width: 3840px) {
  .contentContainer {
    max-width: 2560px;
    max-height: 1440px;
  }
}
