.container {
  /* display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid var();
  borderWidth: 1px;
  borderColor: Colors.borderSecondary;
  borderRadius: 6px;
  marginRight: 20px;
  paddingLeft: 10px;
  padding: 7px 10px 7px 10px;
  fontSize: 13px;
  height: 36px;
  borderStyle: solid; */
  border: 1px solid var(--secondary-gray);
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 15px 10px 10px;
  font-size: 13px;
  color: var(--primary-gray);
  cursor: pointer;
  text-wrap: nowrap;
}
