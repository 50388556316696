.orBlockContainer {
    box-shadow: 0 0 5px var(--primary-gray);
    border-radius: 8px;
    padding: 10px 10px 10px 10px;
}

.dottedConnectorLine {
    border-left: 1px dotted var(--black);
    border-right: 1px dotted var(--black);
    height: 20px;
    width: 90px;
    margin-left: 30px;
}

.logicalOperatorButton {
    padding-top: 6px;
    padding-bottom: 6px;
    background-color: var(--white);
    color: var(--black);
    border: 1px dotted var(--black);
}

.logicalOperatorButtonSelected {
    background-color: var(--primary-blue);
    color: var(--white);
    border: none;
}

.leftButton {
    margin-right: 20px;
}

.saveButton {
    padding-top: 6px;
    padding-bottom: 6px;
}

.deleteButton {
    float: right;
    padding-top: 6px;
    padding-bottom: 6px;
}

.container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.ifBlockHeading {
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 15px;
}

.thenBlockHeading {
    font-weight: 600;
    font-size: 20px;
    margin: 15px 0;
}

.buttonSection {
    display: flex;
    gap: 10px;
    justify-content: flex-end;
    box-sizing: border-box;
}

.formSection {
    overflow-y: scroll;
    padding-bottom: 200px;
    padding-left: 3px;
    padding-right: 3px;
}
