.container {
    position: relative;
}

.line {
    width: 1px;
    height: 110%;
    background-color: var(--primary-gray);
    position: absolute;
    bottom: 60px;
    left: 4px;
}

.circle {
    position: absolute;
    height: 9px;
    width: 9px;
    border-radius: 100%;
    top: 15px;
    left: 0px;
    background-color: var(--primary-gray);
}

.activityCard {
    width: calc(100% - 15px);
    height: 80px;
    border: 2px solid var(--secondary-gray);
    border-radius: 8px;
    cursor: pointer;
    margin-left: 15px;
}

.activityCard:hover {
    background-color: var(--secondary-gray);
}

.selected {
    background-color: var(--secondary-gray);
}

.activityHeader {
    display: flex;
    height: 40px;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    font-size: var(--font-size-12);
    color: var(--font-dark);
    padding: 10px 0 0 10px;
}

.statusBadge {
    width: fit-content;
}

.requestDate {
    font-size: var(--font-size-12);
    color: var(--primary-gray);
    height: 40px;
    padding: 10px 0 0 18px;
}
