.badgeWrapper {
  width: 100%;
  display: flex;
}
.badge {
  font-size: 14px !important;
  padding: 4px 8px;
  width: fit-content;
  height: fit-content;
  font-weight: 500;
}

.statusClass {
  width: auto;
  min-width: 8px;
  height: 100%;
}
.tableRowText {
  border-bottom-width: 1px;
  border-bottom-style: solid;
  text-align: left;
  font-size: 14px;
  font-weight: 400;
  color: var(--primary-gray);
  padding-left: 1rem;
  cursor: pointer;
}

.badgeExtraStyles {
  width: 150px;
}

.tableRow {
  height: 50px;
  display: table;
  width: 100%;
  table-layout: fixed;
  overflow-x: auto;
}

.tableRow:hover {
  background-color: var(--secondary-gray);
}
