.container {
  padding: 0px 34px;
}
.titleId {
  font-size: var(--font-size-20);
  font-weight: 500;
  color: var(--font-dark);
  display: flex;
  align-items: center;
}


.burqIdTitle {
  font-size: var(--font-size-14);
  color: var(--primary-gray);
}
