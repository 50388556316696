.orderDetails {
  margin-top: 20px;
  /* border-bottom: 1px solid var(--secondary-gray); */
}

.row {
  display: flex;
  align-items: center;
  margin-bottom: 28px;
}
.summaryTitle {
  font-weight: 600;
  font-size: var(--font-size-20);
  color: var(--font-dark);
}

.icon {
  font-size: 28px;
  color: var(--primary-gray);
  margin-right: 16px;
}

.providerLogo {
  height: 40px;
  width: 40px;
  flex-shrink: 0;
  border-radius: 30px;
  overflow: hidden;
  object-fit: cover;
  margin-right: 10px;
}

.smallerIcon {
  font-size: 26px;
  color: var(--primary-gray);
  margin-right: 19px;
}

.action {
  display: flex;
  align-items: center;
}

.phoneIcon {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--primary-blue);
  font-size: 16px;
  padding-left: 1px;
  margin-left: 46px;
  margin-right: 8px;
}

.label {
  color: var(--primary-gray);
  width: 27%;
  font-size: var(--font-size-16);
  flex-shrink: 0;
}

.value {
  color: var(--font-dark);
}

.carType {
  color: var(--font-dark);
  margin-left: 30px;
  text-transform: capitalize;
  display: flex;
  align-items: center;
}

.valueDelivered {
  margin-right: 40px;
  position: relative;
}

.notReroutedReason {
  position: absolute;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  text-align: left;
  color: var(--gray-text);
  white-space: nowrap;
}

.action {
  margin-right: 4px;
  color: var(--font-dark);
}

.badge {
  font-size: var(--font-size-14);
  padding: 6px 10px;
  white-space: nowrap;
}

.statusClass {
  width: auto !important;
  height: 100% !important;
}

.avatarCircle {
  height: 40px;
  width: 40px;
  border-radius: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #28a5c1;
  margin-right: 10px;
  flex-shrink: 0;
}

.carIconCircle {
  height: 40px;
  width: 40px;
  border-radius: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e38c5b;
  margin-right: 10px;
  flex-shrink: 0;
}
