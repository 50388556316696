.phoneNumber {
  height: 46px;
  width: 46px;
  background-color: #f7f5ff;
  border-radius: 14px;
}

.phoneIcon {
  height: 30px;
  width: 30px;
}

.number {
  font-size: var(--font-size-18);
  margin-left: 5px;
}
