.buttonCont {
  font-weight: 600;
  font-size: 16px;
  justify-content: center;
  align-items: center;
  margin: 0px 60px 0px 10px;
  color: var(--primary-gray);
  transition: all 0.5s;
  outline: none;
}
.activeButton {
  color: var(--primary-blue);
}

.bottomLine {
  width: 80px;
  height: 4px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  transition: all 0.5s;
  outline: none;
  background-color: var(--white);
}

.activeBottomLine {
  background-color: var(--primary-blue) !important;
}
