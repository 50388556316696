.container {
  position: relative;
  width: 100%;
  display: flex;
  align-items: start;
  justify-content: space-between;
  padding-left: 40px;
  color: var(--primary-gray);
  margin: 30px 0px;
  
}

.line {
  position: absolute;
  width: 2px;
  background-color: var(--secondary-gray);
  top: 6px;
  bottom: -40px;
  left: 8px;
}

.circle {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: 8px;
  background-color: var(--white);
  top: 0px;
  bottom: 0px;
  left: -3px;
}

.smallCircle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background-color: var(--secondary-gray);
}

.smallest {
  width: 8px;
  height: 8px;
  border-radius: 5px;
  background-color: var(--primary-gray);
}
.greenCircle {
  background-color: #11a75c;
}
.blueCircle {
  background-color: var(--primary-blue);
}
.redCircle {
  background-color: #ff3838;
}

.fontDark {
  color: var(--font-dark);
  font-weight: 400;
}

.date {
  font-weight: 300;
}

.time {
  font-weight: 300;
  font-size: var(--font-size-14);
}
