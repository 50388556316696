:root {
    --font-size-10: 10px; /* xxsfontsize */
    --font-size-12: 12px; /* xsfontsize */
    --font-size-14: 14px; /* smfontsize */
    --font-size-16: 16px; /* mdfontsize */
    --font-size-18: 18px; /* lgfontsize */
    --font-size-20: 20px; /* xlfontsize */
    --font-size-24: 24px; /* px24 */
    --font-size-28: 28px; /* xxlfontsize */
    --font-size-mobile-bigger: 30px;
}

@media screen and (max-width: 1223px) {
    :root {
        --font-size-10: 16px;
        --font-size-12: 20px;
        --font-size-14: 24px;
        --font-size-16: 28px;
        --font-size-18: 32px;
        --font-size-20: 36px;
        --font-size-24: 40px;
        --font-size-28: 44px;
        --font-size-mobile-bigger: 16px;
    }
}
