.giveFeedback {
  z-index: 19;
  background-color: var(--primary-blue);
  color: white;
  right: -100px;
}

.giveDesktop {
  right: -61px;
}
