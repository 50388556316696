.actionsButton {
  /* border: 1px solid var(--secondary-gray); */
  border-radius: 10px;
  padding: 7px 26px 7px 18px;
  position: relative;
  display: flex;
  align-items: center;
  font-size: var(--font-size-16);
  /* color: var(--primary-blue); */
  color: var(--font-dark);
  font-weight: 500;
}

.actionsButton:hover {
  background-color: var(--secondary-gray);
  color: var(--primary-gray);
}

.activeAction {
  background-color: #eff5ff !important;
  color: var(--primary-blue) !important;
}

.active {
  opacity: 0.5;
}

.disabled {
  opacity: 0.5;
}

.orderIdTitle {
  color: var(--primary-gray);
}

.tooltipExtraStyle {
  width: 256px !important;
}
