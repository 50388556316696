.inputWrapper {
    margin: 6px 4px;
    width: 90%;
}

.input {
    font-family: Poppins;
    font-size: 14px;
    border: 0px;
    background: white;
    padding: 3px 10px;
    border-radius: 8px;
    outline-width: 0px;
    width: 100%;
    font-weight: 400;
}

.inputRightIcon {
    font-family: Poppins;
    font-size: 14px;
    outline-width: 0px;
    font-weight: 400;
    background: #F7FAFC;
    color: #CDCDCD;
    text-align: center;
    min-width: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 0px;
    border-radius: 10px 10px 0px 0px;
}

.inputLeftIcon {
    font-family: Poppins;
    font-size: 14px;
    outline-width: 0px;
    font-weight: 400;
    background: #F7FAFC;
    color: #CDCDCD;
    text-align: center;
    min-width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-left: 0px;
    border-radius: 10px 0px 0px 10px;
}

.inputForEdit {
    width: 100%;
    border: 1px solid #F2F2F7;
    border-radius: 10px;
    display: flex;
    /* justify-content: rightIcon ? space-between : undefined; */
    justify-content: space-between;
}
