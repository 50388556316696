.container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.body {
    width: 100%;
    max-width: 600px;
    padding: 20px 40px;
    box-sizing: border-box;
    text-align: center;
}
