@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");

.container {
  max-width: 80%;
  margin-top: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  margin-bottom: 20px;
}
.title {
  color: #222;
  text-align: center;
  font-family: "Inter", sans-serif;
  font-size: 40px;
  font-weight: 500;
  margin-bottom: 20px;
  margin-top: 20px;
}

.titleHelper {
  color: #424242;
  text-align: center;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 36px;
}

@media (max-width: 1023px) {
  .container {
    width: 90vw;
  }
}
