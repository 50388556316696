.cardContainer {
    width: 32.4%;
    height: 190px;
    padding: 16px;
    border: 1px solid var(--secondary-gray);
    border-radius: 10px;
}

.cardBody {
    height: 80%;
    cursor: pointer;
}

.topSection {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 8px;
}

.logo {
    height: 46px;
    overflow: hidden;
    object-fit: cover;
    color: var(--font-dark)
}

.name {
    font-weight: 600;
    font-size: var(--font-size-16);
    line-height: 21px;
    text-transform: capitalize;
    margin: 6px 0;
    color: var(--font-dark);
}

.actionMenu {
    top: 18px;
    right: -14px;
    position: relative;
    padding: 10px;
}

.dotIcon {
    transform: rotate(90deg);
}

@media screen and (max-width: 1223px) {
    .cardContainer {
        width: 49%;
    }
}
