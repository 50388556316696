.forstContainer {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid var(--secondary-gray);
}
.monthText {
  font-weight: 400;
  font-size: var(--font-size-12);
  margin-bottom: 0px;
  margin-top: 5px;
}
.lastContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  overflow-x: scroll;
  gap: 20px;
  border-top: 1px solid var(--secondary-gray);
  margin-top: 6px;
  padding-top: 10px;
}
.providerLegend {
  display: flex;
  align-items: center;
}
.providerTitle {
  font-size: 12px;
  color: var(--primary-gray);
  margin-left: 4px;
  text-wrap: nowrap;
}
