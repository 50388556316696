.preferenceSection {
    width: 100%;
    padding-left: 100px;
    margin-top: 10px;
}

.header {
    font-size: var(--font-size-14);
    color: var(--font-dark);
    margin-bottom: 6px;
    margin-top: 10px;
    display: flex;
    align-items: center;
}
.tooltipExtras {
    background-color: var(--white) !important;
    border: 1px solid var(--table-border-gray);
    border-radius: 10px;
    padding: 14px;
    margin-left: 22px;
}

.contentContainer {
    width: 450px;
    background-color: var(--white);
    color: var(--font-dark);
    font-size: 14px;
    text-wrap: wrap;
    padding: 10px;
}
.contentTitles {
    font-weight: 500;
}
.contentDescs {
    font-weight: 300;
}
.infoIcon {
    font-size: 20px;
    color: var(--light-gray);
    position: relative;
}
.fieldLabel {
    color: var(--primary-gray);
    font-size: var(--font-size-14);
    display: inline-block;
}

.radioItems {
    display: flex;
}

.radioItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 6px;
    margin-right: 10px;
}
