.copyIcon {
  cursor: pointer;
  position: relative;
  display: inline-block;
}


.rectangle {
  position: absolute;
  top: 14px;
  left: -10px;
  transform: rotate(45deg);
  border-left: 1px solid var(--table-border-gray);
  border-bottom: 1px solid var(--table-border-gray);
  height: 18px;
  width: 18px;
  background-color: var(--white);
  z-index: 999;
  border-bottom-left-radius: 2px;
}

.copyIcon:hover {
  /* background-color: var(--secondary-gray); */
}
.copyIcon:active {
  /* background-color: var(--light-gray); */
}

.tooltip {
  position: absolute;
  top: 50%; /* Adjust based on your design */
  left: 50%;
  transform: translateX(0px);
  transform: translateY(-17%);
  background-color: #333;
  color: #fff;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
  z-index: 999;
  opacity: 0; /* Start hidden */
  transition: opacity 0.3s ease-in-out;
  display: none;
}

.fadeIn {
  opacity: 1; /* Fade in to visible */
  display: block;
}

.fadeOut {
  opacity: 0; /* Fade out to hidden */
  display: none;
}
