* {
  font-family: "Poppins", sans-serif;
}
.absoluteCenter {
  position: absolute;
  top: 0px;
  left: 50%;
  transform: translate(-50%, -50%);
}
.imageContainer {
  height: 88px;
  width: 100%;
  border-bottom: 1px solid var(--light-gray);
}

.headerCont {
  width: 100%;
  padding: 8px;
  border-bottom: 1px solid var(--light-gray);
}

.image {
  border-radius: 10px;
  object-fit: cover;
  /* height: 100%; */
  height: 72px;
  /* width: 72px; */
  max-width: 144px;
  min-width: 72px;
}

.estimatedTime {
  /* width: 50%; */
  text-align: center;
  font-weight: 500;
  font-size: var(--font-size-20);
  color: #171725;
}

.eta {
  font-weight: 500;
  font-size: var(--font-size-20);
  color: #171725;
  text-align: center;
}

.badge {
  font-size: var(--font-size-20);
  /* height: 50px; */
  /* white-space: nowrap; */
  padding: 10px 40px;
}

.statusClass {
  width: auto;
  min-width: 8px;
  height: 100%;
}

.deliveryId {
  font-size: var(--font-size-16);
}

.estimatedText {
  width: 100%;
  text-align: center;
  font-weight: 400;
  font-size: var(--font-size-14);
  color: #92929d;
  margin-top: 4px;
}

.fullWidthLine {
  width: 100%;
  height: 7px;
  background-color: #dadada;
  border-radius: 100px;
  margin-bottom: 60px;
  margin-top: 10px;
  overflow: hidden;
}

.insideFullLine {
  background-color: #0062ff;
  height: 100%;
  border-radius: 100px;
}

.oneLine {
  width: 18%;
  height: 5px;
  background: #dadada;
  border-radius: 100px;
}

.insideLine {
  height: 100%;
  background-color: #0062ff;
  border-radius: 100px;
}

.delInfoContainer {
  border-radius: 10px;
  margin-top: 12px;
}

.driverCont {
  border-bottom: 1px solid var(--light-gray);
  padding: 0px 0px 24px 0px;
}
.idTitle {
  font-weight: 500;
  color: #92929d;
  font-size: var(--font-size-16);
}

.id {
  font-weight: 500;
  color: #171725;
  font-size: var(--font-size-14);
}

.delIdCont {
  border-bottom: 1px solid var(--light-gray);
  padding-bottom: 20px;
}

.stopsTitles {
  font-size: var(--font-size-16);
  color: #92929d;
}
.stopsAddresses {
  font-size: var(--font-size-16);
  color: #171725;
  font-weight: 500;
  text-transform: capitalize;
}

.circle {
  position: absolute;
  left: -48px;
  width: 40px;
  height: 40px;
  border-radius: 100px;
  top: 0px;
  z-index: 1;
}
.dropoffBigCircleColor {
  background-color: #deebff;
}
.smallCircle {
  width: 14px;
  height: 14px;
  border-radius: 100px;
}

.pickupBigCircleColor {
  background-color: #e8f9ee;
}
.pickupSmallCircleColor {
  background-color: #0ebc93;
}

.line {
  color: #92929d;
  background-image: repeating-linear-gradient(0deg, #92929d 0, #92929d 5px, transparent 5px, transparent 8px);
  width: 1px;
  position: absolute;
  bottom: 20px;
  height: 990%;
  left: -29px;
}

.borderBottom {
  padding-bottom: 20px;
  border-bottom: 1px solid var(--light-gray);
}

.phoneNumber {
  height: 46px;
  width: 46px;
  background-color: #f7f5ff;
  border-radius: 14px;
}

.maskingColor {
  background-color: #e8e8e8;
}

.logoBranding {
  border-radius: 4px;
  width: 144px;
  height: 100%;
  background-color: #e8e8e8;
  color: #171725;
  font-weight: 500;
  text-align: center;
}
.branding {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='9' ry='9' stroke='%2392929DFF' stroke-width='2' stroke-dasharray='6' stroke-dashoffset='5' stroke-linecap='round'/%3e%3c/svg%3e");
  border-radius: 0px;
  border-radius: 10px;
  width: 100%;
  min-height: 200px;
  background-color: #e8e8e8;
}
.yourImageHere {
  color: #171725;
  font-weight: 500;
  text-align: center;
}
.ratio {
  color: #92929d;
  /* color: var(--primary-gray); */
}

@media (max-width: 1223px) {
  .image {
    height: 90%;
  }
  .imageContainer {
    height: 140px;
    justify-content: center !important;
  }
  .badge {
    padding: 20px 80px;
  }

  .delInfoContainer {
    border: 0;
    box-shadow: none;
  }

  .phoneNumber {
    height: 76px;
    width: 76px;
    background-color: #f7f5ff;
    border-radius: 24px;
  }

  .phoneIcon {
    height: 30px;
    width: 30px;
  }

  .addresses {
    padding-left: 82px;
  }

  .line {
    color: #92929d;
    background-image: repeating-linear-gradient(0deg, #92929d 0, #92929d 5px, transparent 5px, transparent 8px);
    width: 1px;
    position: absolute;
    bottom: 20px;
    height: 990%;
    left: -46px;
  }

  .circle {
    position: absolute;
    left: -82px;
    width: 70px;
    height: 70px;
    border-radius: 100px;
    top: 0px;
    z-index: 999;
  }
  .smallCircle {
    width: 30px;
    height: 30px;
    border-radius: 100px;
  }
  .courierImg {
    height: 100px;
    width: 100px;
    margin-right: 40px;
  }
}
