.container {
    padding-top: 0;
    background-color: var(--bg-dark-secondary);
    z-index: 999;
    max-width: 300px;
    width: 300px;
    display: none;
    position: absolute;
    left: 100%;
    top: 0;
    border-radius: 6px;
    box-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1) 0 8px 10px -6px rgb(0 0 0 / 0.1);
    flex-direction: column;
    justify-content: space-between;
}

.container.show {
    display: flex;
}

.form {
    width: 100%;
    border-width: 1px;
    border-radius: 6px;
    border-color: var(--secondary-gray);
    padding: 12px 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: var(--bg-settings);
}

.input {
    outline: none;
    width: 100%;
    height: 100%;
    margin-left: 10px;
    background-color: transparent;
    color: var(--primary-gray);
    font-size: var(--fone-size-12);
}
