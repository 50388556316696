.container {
  position: relative;
  margin-right: 16px;
}
.placeHolder {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: var(--sky-blue);
  border-radius: 6px;
  width: 140px;
  height: 40px;
  font-size: 13px;
  font-weight: 500;
  color: var(--primary-blue);
  cursor: pointer;
}

.dropdownComponent {
  width: 300px;
  height: 300px;
  position: absolute;
  background-color: var(--white);
  right: 0;
  top: 45px;
  border-radius: 6px;
  box-shadow: -1px 39px 112px -13px rgba(0, 0, 0, 0.3);
  overflow-y: auto;
}

.fixedContainer {
  width: 100%;
  color: var(--font-dark);
  padding: 12px 20px 10px 20px;
}
.borderBottom {
  border-bottom: 1px solid var(--secondary-gray);
}
.fixedTitle {
  font-weight: 500;
  color: var(--primary-gray);
  font-size: 13px;
}

.fixedHeaderText{
  font-weight: 300;
  margin: 4px 0px;

}

.oneItem {
  color: var(--font-dark);
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 6px 0px;
}
.draggableText {
  font-weight: 400;
  font-size: 14px;
  margin-left: 4px;
}

.grab {
  cursor: grab;
}
