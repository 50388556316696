.container {
  width: 100%;
  padding: 0px 20px 0px 20px;
}

.noBorder {
  border: 0px !important;
}

.flexContainer {
  display: flex;
  flex-direction: row;
  border-bottom: solid 1px var(--secondary-gray);
  padding: 20px 20px 20px 0px;
  align-items: center;
  justify-content: space-between;
}
.infoIcon {
  color: var(--light-gray);
}
.text {
  color: var(--font-dark);
  font-weight: 500;
  display: flex;
}

.colorInputContainer {
  width: 80px;
  height: 56px;
  border: solid 1px var(--secondary-gray);
  border-radius: 10px;
  overflow: hidden;
}

.colorInput {
  height: 30px;
}

.colorText {
  font-size: var(--font-size-12);
  font-weight: 500;
}

.tooltip {
  background-color: var(--white);
  width: auto;
  font-size: var(--font-size-12);
}

.copyIcon {
  margin: 0px !important;
}
