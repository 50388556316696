.createDelText {
  font-weight: 500;
  font-size: var(--font-size-16);
  color: var(--font-dark);
}

.bgDarkSecondary {
  background-color: var(--bg-dark-secondary);
}

.bottomButtonCont {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

.continueButton {
  padding: 12px 96px;
  border-radius: 8px;
  background-color: var(--primary-blue);
  color: #fafafa;
  font-size: var(--font-size-16);
}
