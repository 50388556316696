.badge {
  font-size: var(--font-size-14);
}

.titles {
  font-size: var(--font-size-16);
  color: var(--primary-gray);
  text-align: left;
  width: 55%;
}
.titlesExterRef {
  font-size: var(--font-size-16);
  color: var(--primary-gray);
  text-align: right;
  width: 44%;
}