.cont {
  height: 69px;
  width: 69px;
  border-radius: 40px;
  border-width: 4px;
  border-color: #ff3f3f;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 48px;
  padding-top: 5px;
}

.firstLine {
  font-size: 12px;
  line-height: 10px;
}

.secondLine {
  font-size: 16px;
  line-height: 15px;
  color: #ff3f3f;
}
