.container {
    padding-top: 0;
    z-index: 999;
    max-width: 300px;
    width: 300px;
    display: none;
    flex-direction: column;
    justify-content: space-between;
}

.container.show {
    display: flex;
}

.form {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.inputContainer {
    display: flex;
    align-items: center;
    width: 100%;
    margin-left: 10px;
    background-color: var(--bg-settings);
    color: var(--primary-gray);
    font-size: 13px;
    border-color: var(--secondary-gray);
    border-radius: 6px;
}

.input {
    outline: none;
    width: 100%;
    height: 100%;
    background-color: transparent;
    border-radius: 6px;
    padding: 6px 10px;
}
