.cont {
  /* box-shadow: 0px 20px 50px 0px rgba(220, 224, 249, 0.5); */
}
.download {
  font-size: var(--font-size-14);
  color: var(--primary-blue);
  margin-left: 16px;
}
.imagesTitle {
  color: var(--font-dark);
}

.downloadButton {
  color: var(--primary-blue);
}

.imagesContainer {
  padding: 16px;
  border-radius: 10px;
  border: 1px solid var(--secondary-gray);
  flex-shrink: 0;
  position: relative;
  margin-top: 10px;
}
.deleteImage {
  cursor: pointer;
  position: absolute;
  top: -10px;
  right: -10px;
  z-index: 999;
  height: 20px;
  width: 20px;
  border-radius: 100%;
  background-color: var(--table-row-text-gray);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.deleteImage:hover {
  background-color: var(--red-dark);
}

.images {
  height: 70px;
  width: 70px;
  display: block;
  flex-shrink: 0;
}
.pod {
  display: none;
}

@media (max-width: 1223px) {
  .images {
    height: 150px;
    width: 150px;
  }
  .imagesContainer {
    border: 0;
  }
  .cont {
    box-shadow: none;
  }
  .pod {
    display: block;
    font-size: var(--font-size-18);
    color: var(--font-dark);
    font-weight: 500;
  }
}
