.title {
  font-size: var(--font-size-16);
  line-height: 18px;
  margin-top: 24px;
  color: var(--font-dark);
  width: 100%;
  background-color: var(--white-blue);
  height: 31px;
  align-items: center;
  display: flex;
  border-radius: 5px 5px 0px 0px;
  padding-left: 12px;
}

.amountCont {
  background-color: var(--sky-blue);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 0px 0px 10px 10px;
  padding: 14px 14px 15px;
}

.detailsOneLine {
  width: 100%;
  border-bottom-width: 0.25px;
  border-color: #0000004d;
}

.noBorder {
  border: 0px;
}

.oneLineTitle {
  font-size: var(--font-size-16);
  text-transform: capitalize;
}
.light {
  color: var(--primary-gray);
}

.dark {
  color: var(--font-dark);
}

.fontBigger {
  font-size: var(--font-size-18);
}

@media screen and (max-width: 1223px) {
  .title {
    height: 60px;
  }
}
