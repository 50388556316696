.container {
    position: absolute;
    width: max-content;
    right: 0px;
    outline: none;
    background-color: var(--white);
    top: 25px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border: 2px solid var(--secondary-gray);
    border-radius: 10px;
    padding: 0 16px;
}

.actionItem {
    font-size: var(--font-size-16);
    font-weight: 500;
    width: calc(100% + 32px);
    padding: 8px 0 8px 16px;
    text-align: start;
    color: var(--primary-gray);
    margin-left: -16px;
}

@media screen and (max-width: 1223px) {
    .actionItem {
        padding: 18px 24px;
    }
}
