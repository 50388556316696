.heading {
  font-family: Poppins;
  font-size: 22px;
  font-weight: 500;
  line-height: 37.4px;
  text-align: left;
  color: var(--font-dark);
}

.description {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.10000000149011612px;
  text-align: left;
  color: var(--gray-label);
  margin-bottom: 16px;
}

.toggleLabel {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 27.2px;
  text-align: left;
  color: var(--font-dark);
}

.toggle {
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 20px;
  justify-content: space-between;
}

.toggleDescription {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.10000000149011612px;
  text-align: left;
  color: var(--gray-label);
  margin-bottom: 16px;
}
