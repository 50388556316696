.container {
  background-color: var(--white-blue);
  border-radius: 10px;
  padding: 14px 20px;
  margin-bottom: 24px;
}

.refundedTitles {
  font-size: var(--font-size-14);
  width: 300px;
  display: flex;
  justify-content: space-between;
}

.refundedStatus {
  font-size: mdfontsize;
  margin-left: 10px;
}
