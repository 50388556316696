.container {
  padding: 0px 16px;
  margin: 4px 0px;
  cursor: pointer;
}
.smallerCont {
  padding: 0px !important;
}

.buttonContainer {
  display: flex;
  align-items: center;
  /* width: 100%; */
  padding: 12px 4px 12px 14px;
  color: var(--primary-gray);
  /* justify-content: space-between; */
}
.closedSidebar {
  padding: 4px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0px;
}
.buttonContainer:hover .iconClasses {
  fill: var(--font-dark);
}

.buttonContainer:hover {
  background-color: var(--secondary-gray);
  border-radius: 10px;
  color: var(--font-dark);
}

.iconClasses {
  fill: var(--primary-gray);
}

.buttonTitle {
  text-transform: capitalize;
  font-size: var(--font-size-16);
  font-weight: 500;
  margin-left: 16px;
  white-space: nowrap;
}

.smallTitle {
  text-transform: capitalize;
  font-size: var(--font-size-16);
  font-weight: 500;
  white-space: nowrap;
}
.active {
  color: var(--primary-blue) !important;
  fill: var(--primary-blue) !important;
  background-color: var(--secondary-gray) !important;
  border-radius: 10px;
}

.activeWorkflow {
  color: var(--primary-blue) !important;
  fill: var(--primary-blue) !important;
  /* background-color: var(--secondary-gray) !important; */
  border-radius: 10px;
}
