.cont {
  /* box-shadow: 0px 20px 50px 0px rgba(220, 224, 249, 0.5); */
}
.download {
  font-size: var(--font-size-14);
  margin-top: 24px;
  color: var(--font-dark);
  background-color: var(--white-blue);
  padding: 8px 12px;
  border-radius: 5px 5px 0px 0px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}
.imagesTitle {
  color: var(--font-dark);
}

.downloadButton {
  color: var(--primary-blue);
}

.imagesContainer {
  /* background-color: var(--bg-grey); */
  /* box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1); */
  /* border-radius: 0px 0px 10px 10px; */
  border-radius: 10px;
  padding: 18px;
  /* border: 1px solid var(--light-gray); */
}

.images {
  height: 70px;
  width: 70px;
}
.pod {
  display: none;
}

@media (max-width: 1223px) {
  .images {
    height: 150px;
    width: 150px;
  }
  .imagesContainer {
    border: 0;
  }
  .cont {
    box-shadow: none;
  }
  .pod {
    display: block;
    font-size: var(--font-size-18);
    color: var(--font-dark);
    font-weight: 500;
  }
}
