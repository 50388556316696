.uploadTitle {
  color: var(--font-dark);
  margin: 20px 0px;
  font-weight: 500;
}

.maxText {
  font-size: 12px;
  color: var(--primary-gray);
  font-weight: 400;
}

/* UploadComponent.module.css */

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  margin-bottom: 20px;
}

.errorMessage {
  color: #eb4a4a;
  margin-top: 10px;
  font-size: 12px;
  font-weight: 300;
}
.uploadContainer {
  width: 100%;
  height: 230px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px dashed #0062ff;
  border-radius: 10px;
}

.textDark {
  color: var(--primary-gray);
  font-size: 12px;
}

.addImageCont {
  padding: 16px;
  border-radius: 10px;
  border: 1px solid var(--secondary-gray);
  flex-shrink: 0;
  position: relative;
  margin-top: 10px;
  cursor: pointer;
}

.iconCont {
  height: 70px;
  width: 70px;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
}

.dropContainer {
  width: 100%;
  height: 170px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px dashed #0062ff;
  border-radius: 10px;
}

.inputFile {
  margin-bottom: 15px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.button:disabled {
  background-color: #a0a0a0;
  cursor: not-allowed;
}

.progressContainer {
  margin-top: 20px;
  width: 100%;
}

.progressItem {
  margin-bottom: 10px;
}

.progressText {
  font-size: 14px;
  color: #333;
}
