.container {
  width: 100%;
  height: 100%;
  background-color: var(--white);
  padding: 24px 0px;
  overflow-y: auto;
  padding-bottom: 200px;
  position: relative;
}

.summaryTitle {
  font-weight: 600;
  font-size: var(--font-size-20);
  color: var(--font-dark);
}
.paddingCont {
  padding: 0px 34px;
}

.eventDisclTitle {
  margin-left: 34px;
}
.disclosure {
  border-bottom: 1px solid var(--secondary-gray);
  margin: 24px 0px;
}
.orderValue {
  /* width: 300px; */
  font-weight: 600;
  padding-right: 18px;
  font-size: var(--font-size-20);
  color: var(--font-dark);
}

.notFoundSvgCont {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.notFoundTItle {
  font-size: var(--font-size-24);
  color: var(--font-dark);
  font-weight: 500;
  margin-top: 20px;
}

.notFoundDesc {
  font-size: var(--font-size-16);
  color: var(--primary-gray);
  font-weight: 400;
  text-align: center;
  margin-top: 30px;
}

.fontDarker {
  color: var(--font-dark) !important;
}
