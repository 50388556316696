.podIncident {
  color: var(--font-dark);
  font-weight: 500;
  margin-top: 20px;
}

.noWrap {
  text-wrap: nowrap;
}
.title {
  color: var(--font-dark);
}

.dropDownComponent {
  width: 60% !important;
  margin-right: 0px !important;
  color: var(--font-dark) !important;
  /* font-size: var(--font-size-18) !important; */
  font-size: 18px !important;
}
.dropdownInput {
  background-color: var(--secondary-gray) !important;
  padding-left: 20px !important;
  color: var(--font-dark) !important;
}
.placeholderStyle {
  font-size: 16px !important;
}
.dropdownContainer {
  width: 100%;
  border-radius: 0px 0px 10px 10px;
  color: var(--font-dark);
  background-color: var(--secondary-gray);
  font-size: var(--font-size-14);
  position: absolute;
  z-index: 999;
  max-height: 150px !important;
  overflow-y: scroll;
  border-top: 0px !important;
}
.oneItem {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 16px;
}

.oneItemInDropdown {
  padding: 10px 20px !important;
  cursor: pointer;
  text-transform: capitalize;
  width: 100%;
  /* font-size: var(--font-size-16); */
  font-size: 16px;
}
.tooltipExtras {
  background-color: var(--white) !important;
  border: 1px solid var(--table-border-gray);
  border-radius: 10px;
  padding: 14px;
  margin-left: 25px;
}
.infoIcon {
  font-size: 20px;
  color: var(--light-gray);
  position: relative;
}
.contentContainer {
  width: 450px;
  background-color: var(--white);
  color: var(--font-dark);
  font-size: 14px;
  text-wrap: wrap;
  padding: 10px;
}

.buttonsContainer {
  display: flex;
  justify-content: end;
  align-items: center;
  position: relative;
}

.incidentReasonDropDownComponent {
  width: 100% !important;
  margin-right: 0px !important;
  color: var(--font-dark) !important;
  font-size: 18px !important;
}

.incidentReasonError {
  color: var(--red-dark) !important;
  font-family: "Poppins";
  font-size: "12px";
  padding: "0 10px 3px 12px";
  text-align: "left";
  margin-top: 20px;
}

.incidentReasonDropDownContentContainer {
  max-height: 250px !important;
  overflow-y: auto;
}

@media (max-width: 1224px) {
  .buttonsContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1rem;
    align-items: end;
  }
  .buttonsContainer button {
    width: 100%;
  }
}
