.container {
    padding: 20px 20px 0px 20px;
    height: calc(100vh - 70px);
    position: relative;
    width: 100%;
    /* background-color: red; */
    /* display: block; */
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0px;
}

.heading {
    font-size: 24px;
    font-weight: 600;
    color: var(--font-dark);
}

.button {
    font-size: 14px;
    padding: 5px 10px;
    border-color: var(--primary-blue);
}

.tabButtonCont {
    margin: 0px 20px 0px 10px !important;
    font-weight: 400 !important;
}

.tabBottomLine {
    width: 30px !important;
}

.buttonContent {
    display: flex;
    gap: 5px;
    align-items: center;
}
.uploadButtonText {
    color: var(--primary-blue);
}
.table th,
.table td {
    white-space: nowrap;
}

.table {
    width: auto;
    height: calc(100vh - 324px);
    border-collapse: collapse;
}

.tableBody {
    overflow-y: scroll;
    height: calc(100% - 64px);
    table-layout: fixed;
    display: block;
}

.tableContainer {
    border: 1px solid var(--table-border-gray);
    border-width: 1px 1px 0px 1px;
    border-radius: 20px 20px 0px 0px;
    width: 100%;
    overflow-x: auto;
    /* overflow-y: hidden; */
}

.paginationContainer {
    border: 1px solid var(--table-border-gray);
    padding-right: 10px;
    border-width: 0px 1px 1px 1px;
    border-radius: 0px 0px 10px 10px;
}

.tableFooter {
    border-top: 1px solid var(--table-border-gray);
}

.tableHeader {
    height: 60px;
    display: table;
    width: 100%;
    table-layout: fixed;
    border-bottom: 1px solid var(--table-border-gray);
}

@media (max-width: 640px) {
    .table {
        overflow-x: scroll;
    }
    .tableHeader {
        overflow-x: scroll;
    }
}

@media (max-width: 1224px) {
    .table {
        min-width: 100%;
        height: calc(100vh - 400px);
    }
    .tableBody {
        height: calc(100% - 80px);
    }
}
