/* all colors should be in accending from light to dark */
:root {
    /* WHITE */
    --white: #ffffff;
    --bg-grey: #f7fafc;
    --bg-dark-secondary: #ffffff;

    /* BLUE */
    --light-blue: #eff5ff;
    --white-blue: #eff4f8;
    --sky-blue: #f7fafc;
    --primary-blue: #0062ff;

    /* GREY */
    /* Sorted from top to bottom, do not create any other shade. Select the best matching shade. */
    --bg-settings: #f5f5f5;
    --secondary-gray: #f2f2f7;
    --lighter-gray: #fafafb;
    --light-gray: #dadada;
    --primary-gray: #92929d;
    --dark-gray: #455468;
    --font-dark: #171725;
    --warning-grey: #f2f2f2;
    --gray-label: #808080;
    --table-row-text-gray: #808080;
    --table-border-gray: #e5e7eb;
    --secondary-background: #f5f5f5;
    --gray-text: #838383;
    --gray-heading: #3B3B3B;
    --gray-sub-heading: #545454;

    /* GREEN */
    --green-dark: #3dd598;

    /* RED */
    --red-dark: #ff3f3f;

    /* ORANGE */
    --primary-orange: #ff974a;
    --warning-orange: #ff8528;

    /* BLACK */
    --black: #000000;

    /* SHADOW */

    --shadowColor: rgba(71, 63, 79, 0.16);

    --brand-blue-800: #003B99;
    --brand-blue-100: #E5EFFF;

    --menu-text: #768196;
    --menu-icon: #5D6576;
    --menu-item-hover: #eceef2;
    --menu-divider-line: "#eceef2";
    --menu-header-text: "#1C2029";
}
