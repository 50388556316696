.buttonContainer {
  font-size: 16px;
  display: flex;
  align-items: center;
}

.iconColor {
  color: white;
}

.buttonRecommended {
  background-color: #4CAF50;
  border: 1px solid #4CAF50;
  color: white;
}

.redOutline {
  border: 1px solid var(--red-dark);
}

.dropDownComponent {
  width: 100% !important;
  margin-bottom: 20px;
}
