.container {
    display: flex;
    flex-direction: row;
    width: 100%;
    color: var(--primary-gray);
    padding: 7px 10px;
    gap: 10px;
    font-size: 14px;
    overflow-x: auto;
}

.filterItem {
    border: 1px solid #d8d8d8;
    border-radius: 6px;
    padding: 6px 10px;
    position: relative;
    display: inline-block;
    white-space: nowrap;
}

.filterItem > svg {
    position: absolute;
    top: -10px;
    right: -12px;
    display: none;
}

.filterItem:hover > svg {
    display: block;
}

.close {
    font-size: 24px;
    color: var(--primary-gray);
    background-color: var(--white);
    border-radius: 16px;
    cursor: pointer;
}
