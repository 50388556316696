.container {
  width: 100%;
  border-radius: 8px;
  background-color: var(--lighter-gray);
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.headerTextContainer {
  display: flex;
  gap: 0.25rem;
}

.headerText {
  color: var(--font-dark);
  font-weight: 600;
  font-size: var(--font-size-16);
}

.feesCont {
  border-top-width: 2px;
  border-color: var(--secondary-gray);
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: end;
}

.totalText {
  color: var(--font-dark);
  font-weight: 700;
  font-size: var(--font-size-24);
}

.tooltipContainerForIcon {
  width: 269px;
  border-radius: 6px !important;
  border-color: #d9d9d9 !important;
}

.tooltipText {
  color: #92929d;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0.1px;
}
