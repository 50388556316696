.container {
  background-color: var(--white);
  border-right-width: 1px;
  border-color: var(--secondary-gray);
  z-index: 21;
  position: relative;
}

.trialContainer {
  width: 100%;
  padding: 30px 20px;
}

.mainCont {
  position: relative;
  padding: 10px 14px;
  display: flex;
  flex-direction: column;
  align-items: start;
  border-radius: 6px;
  background-color: var(--sky-blue);
}

.title {
  color: var(--font-dark);
  font-size: 14px;
  text-align: center;
  margin-top: 6px;
  font-weight: 500;
}

.desc {
  color: var(--primary-gray);
  text-align: left;
  font-size: 10px;
}

.giftCont {
  height: 32px;
  width: 32px;
  background-color: var(--white);
  border-radius: 30px;
  position: absolute;
  top: 0%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px -8px 7.3px rgba(0, 0, 0, 0.05);
}

.button {
  font-size: 12px !important;
  margin-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  padding: 4px 10px;
  border: 1px solid #0062ff;
  background-color: transparent !important;
  color: var(--font-dark);
  width: 100%;
}
/* 
.trialContainer {
  width: 100%;
  padding: 30px 20px;
}

.mainCont {
  position: relative;
  padding: 14px 14px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 6px;
  background-color: var(--sky-blue);
}

.title {
  color: var(--font-dark);
  font-size: 14px;
  text-align: center;
  margin-top: 6px;
}

.desc {
  color: var(--primary-gray);
  text-align: center;
  font-size: 10px;
}

.giftCont {
  height: 32px;
  width: 32px;
  background-color: var(--white);
  border-radius: 30px;
  position: absolute;
  top: 0%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px -8px 7.3px rgba(0, 0, 0, 0.05);
}

.button {
  font-size: 12px !important;
  margin-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  padding: 4px 10px;
} */
