.copyIcon {
  color: var(--primary-blue);
  margin-left: 10px;
  font-size: 14px;
  padding: 4px;
  border-radius: 4px;
  cursor: pointer;
  position: relative;
  display: inline-block;
}

.copyIcon:hover {
  background-color: var(--secondary-gray);
}
.copyIcon:active {
  background-color: var(--light-gray);
}

.tooltip {
  position: absolute;
  top: -2px; /* Adjust based on your design */
  left: 50%;
  transform: translateX(20%);
  background-color: #333;
  color: #fff;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
  z-index: 999;
  opacity: 0; /* Start hidden */
  transition: opacity 0.3s ease-in-out
}

.fadeIn {
  opacity: 1; /* Fade in to visible */
}

.fadeOut {
  opacity: 0; /* Fade out to hidden */
}
