.itemsContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
}

.item {
    border-radius: 8px;
    font-size: var(--font-size-14);
    line-height: 24px;
    letter-spacing: 0.10000000149011612px;
    color: var(--dark-gray);
    background-color: var(--secondary-gray);
    padding: 6px 12px;
    text-transform: capitalize;
}
