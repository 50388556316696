.container {
    height: 100%;
    width: 100%;
    padding: 30px 20px;
    overflow-y: scroll;
}

.mainContainer {
    height: 70vh;
}
