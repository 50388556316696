.title {
  min-width: 300px;
  font-size: var(--font-size-16);
  color: var(--primary-gray);
  margin-top: 20px;
  text-transform: capitalize;
}
.number {
  font-size: var(--font-size-14);
  color: var(--font-dark);
  font-weight: 600;
  text-transform: capitalize;
}
