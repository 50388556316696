.sideDrawer {
    height: 100vh;
    overflow-y: scroll;
    background: var(--white);
    position: fixed;
    top: 0;
    right: 0;
    width: 46%;
    z-index: 200;
    box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
    transform: translateX(100%);
    transition: transform 0.3s ease-out;
}

.sideDrawerOpen {
    transform: translateX(0);
}

.backdrop {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 100;
    top: 0;
    right: 0;
}
