.card {
  border-radius: 18px;
  border: 1px solid var(--secondary-gray);
  margin-top: 10px;
}

.icon {
  color: var(--font-dark);
  /* color: var(--primary-gray); */
}

.header {
  padding: 12px 20px;
  display: flex;
  align-items: center;
  /* border-bottom: 1px solid var(--secondary-gray); */
  color: var(--font-dark);
  justify-content: space-between;
}

.reviewStatement {
  flex-grow: 1;
  font-weight: 500;
  margin-left: 20px;
  font-size: var(--font-size-16);
}

.delIdTitle {
  color: var(--primary-gray);
  margin-left: 22px;
  font-size: var(--font-size-14);
}
