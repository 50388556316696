@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");

.container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.logosCont {
  display: flex;
  align-items: center;
  padding: 12px 0px;
}
.platformLogo {
  padding-right: 24px;
  margin-right: 20px;
  border-right: 2px solid #aeaeae;
}
.mainTitle {
  color: #464646;
  text-align: center;
  font-size: 28px;
  font-weight: 500;
  /* flex: 1; */
  font-family: "Inter", sans-serif;
  position: absolute;
  width: 300px;
  left: calc(50% - 130px);
}
.welcomeText {
  font-family: "Inter", sans-serif;
  color: #464646;
  font-family: Inter;
  font-size: 20px;
  font-weight: 400;
  /* max-width: 100px; */
  text-wrap: nowrap;
}

.step {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* flex: 0.5; */
}

.icon {
  height: 34px;
  width: 34px;
  color: #3cad62;
  margin-bottom: 8px;
}

.border {
  border: 1px solid #c0c0c0;
}

.circle {
  width: 38px;
  height: 38px;
  border-radius: 50%;
  color: #757575;
  font-weight: 400;
  margin-bottom: 8px;
  font-size: 16px;

  border: 1px solid #c0c0c0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.active {
  background-color: #4a4a4a;
  color: white;
  height: 80%;
  width: 80%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
}

.label {
  color: #3d3d3d;
  text-align: center;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.line {
  flex-grow: 1;
  height: 1px;
  background-color: #c0c0c0;
  margin: 0 10px;
  border-radius: 100px;
}

@media (max-width: 1023px) {
  .mainTitle {
    width: 150px;
    font-size: 20px;
      left: calc(50% - 80px);
  }
  .welcomeText{
    width: 110px;
    text-wrap: wrap;
    font-size: 14px;
  }
}
