.container {
    margin-top: 36px;
    padding-bottom: 40px;
}

.input {
    font-size: 14px;
    border: 0px;
    padding: 3px 10px;
    border-radius: 8px;
    outline-width: 0px;
    width: 100%;
    font-weight: 400;
    background-color: var(--white);
    color: var(--font-dark);
}

.inputLable {
    font-weight: 500;
    color: var(--font-dark);
    font-size: var(--font-size-18);
    margin-top: 30px;
    margin-bottom: 6px;
    margin-left: 4px;
}

.inputForEdit {
    width: 100%;
    border-width: 1px;
    border: solid var(--secondary-gray);
    padding: 6px 4px;
    border-radius: 10px;
    display: flex;
}

.loaderContainer {
    height: 70vh;
}

.required {
    font-size: var(--font-size-10);
    color: var(--primary-gray);
    margin-left: 4px;
}
