.stopOrPickupText {
  font-weight: 400;
  font-size: var(--font-size-14);
  line-height: 18px;
  color: var(--primary-blue);
  margin-top: 14px;
}
.leftSidePickupText {
  font-weight: 400;
  font-size: var(--font-size-14);
  line-height: 18px;
  color: var(--primary-gray);
  margin-top: 12px;
  width: 99px;
}

.addressCont {
  background-color: var(--bg-grey);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 10px 10px 10px 10px;
  width: 100%;
  padding-bottom: 14px;
  padding-left: 19px;
}

.leftSideTime {
  font-weight: 400;
  font-size: var(--font-size-12);
  color: var(--font-dark);
  width: 99px;
  line-height: 17px;
}

.singleAddress {
  position: relative;
  min-height: 34%;
  padding: 0px 0px 0px 30px;
  margin-left: 20px;
  width: 100%;
  margin-right: 30px;
}

.circle {
  position: absolute;
  height: 14px;
  width: 14px;
  border-radius: 100%;
  top: 15px;
  left: 0px;
}

.addresses {
  font-size: var(--font-size-16);
  color: var(--font-dark);
  font-weight: 500;
}

.pickupDropoff {
  font-size: var(--font-size-16);
  color: var(--primary-gray);
  font-weight: 500;
  margin-bottom: 2px;
  margin-top: 15px;
}

.leftLine {
  width: 1px;
  height: 100%;
  background-color: var(--font-dark);
  position: absolute;
  top: 15px;
  left: 6px;
}

.notesCont {
  min-height: 80px;
  width: 90%;
  background-color: var(--white);
  border-radius: 8px;
  padding: 10px;
  margin-bottom: 10px;
}
