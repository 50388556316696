.container {
  width: 100%;
  border-radius: 8px;
  background-color: var(--lighter-gray);
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.headerText {
  font-size: var(--font-size-16);
  font-weight: 500;
  color: var(--font-dark);
  margin-bottom: 10px;
}

.dollarSign {
  margin-right: 6px;
  font-size: var(--font-size-16);
  color: var(--font-dark);
}

.tipInput {
  padding: 8px 0px 8px 10px;
  border-radius: 8px;
  /* outline: #6ed29d; */
  outline-color: #6ed29d;
  font-size: var(--font-size-14);
  background-color: var(--secondary-gray);
  border: 0px solid #6ed29d;
  /* border-color: #6ed29d; */
  /* border-color: var(--secondary-gray); */
  color: var(--font-dark);
  width: 100px;
  white-space: nowrap;
}

.customTipButton {
  padding: 10px 0px 10px 10px;
  border-radius: 8px;
  outline: none;
  font-size: var(--font-size-12);
  background-color: var(--secondary-gray);
  font-weight: 500;
  border-width: 1px;
  border-color: var(--secondary-gray);
  color: var(--dark-gray);
  width: 100px;
  white-space: nowrap;
}

.err {
  text-align: end;
  color: var(--font-dark);
  margin-top: 10px;
}
.bannerCont {
  position: absolute;
  left: -40px;
  top: 28px;
}

.mostTippedBanner {
  display: flex;
  position: relative;
  background-color: var(--primary-blue);
  height: 22px;
  font-size: var(--font-size-12);
  width: 120px;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: 600;
}

.leftSquare {
  position: absolute;
  left: -11px;
  height: 22px;
  width: 22px;
  transform: rotate(45deg);
  background-color: var(--lighter-gray);
}
.rightSquare {
  position: absolute;
  right: -11px;
  height: 22px;
  width: 22px;
  transform: rotate(45deg);
  background-color: var(--lighter-gray);
}

.dynamicText {
  color: var(--primary-gray);
  font-size: var(--font-size-12);
  font-weight: 500;
  margin-top: 22px;
  width: 80%;
}
