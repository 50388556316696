.container {
  border-bottom-width: 1px;
  border-color: var(--secondary-gray);
  height: 70px;
  padding-left: 4px;
  padding-right: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  z-index: 10;
  background-color: var(--white);
}

.unselectCont {
  margin-left: 30px;
  height: 84%;
  max-width: 350px;
  background-color: var(--bg-grey);
  border-radius: 8px;
  padding: 8px 20px;
}

.unselectText {
  color: var(--font-dark);
  margin-left: 6px;
  font-weight: 500;
}

.avatarCont {
  height: 32px;
  width: 32px;
  border-radius: 100%;
  background-color: var(--lighter-gray);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.userSettingsDropdown {
  font-size: var(--font-size-14);
}
.fontLight {
  color: var(--primary-gray);
}
.fontDark {
  color: var(--font-dark);
}

.triggerAvatarFallback {
  background:#9BD062 !important;
}

.triggerAvatarFallback span {
  font-size: 16px !important;
  font-weight: 600;
}
