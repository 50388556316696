.cardItem {
    height: 182px;
    width: 33.33%;
    border: 1px solid var(--light-gray);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    gap: 10px;
    flex: 0 0 calc(33.333% - 16px);
    margin: 8px;
}

.cartTitle {
    color: var(--font-dark);
    font-size: var(--font-size-16);
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.10000000149011612px;
    text-align: center;
}

.cardDescription {
    font-size: var(--font-size-14);
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.10000000149011612px;
    text-align: center;
    color: var(--primary-gray);
}

.icon {
    height: 31px;
    width: 75px;
    object-fit: contain;
}

@media screen and (max-width: 1223px) {
    .icon {
        height: 36px;
        width: 56px;
    }
}
