.rowContainer {
    display: flex;
    align-items: center;
    border-radius: 8px;
    background-color: transparent;
    opacity: 1;
}


.evenRowContainer {
    background-color: #F2F2F2;
}

.isRowDraging {
    opacity: 0;
}

.rowContent {
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.rowDragButton {
    cursor: move;
    margin: 14px 14px;
}

