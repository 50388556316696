.bellIcon {
  color: var(--font-dark);
  align-self: center;
}

.bellOnHeader {
  /* border: 1px solid var(--secondary-gray); */
  /* border-radius: 100px; */
  /* padding: 7px; */
  position: relative;
}
.bellBigCircle {
  background-color: var(--white);
  padding: 2px;
  position: absolute;
  top: -9px;
  right: -10px;
  border-radius: 100px;
  z-index: 50;
  display: flex;
  justify-content: center;
  align-items: center;
}
.numberBadge {
  font-size: var(--font-size-12);
  border-radius: 100px;
  width: 18px;
  height: 18px;
  background-color: var(--primary-blue);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}
