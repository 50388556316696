.container {
  padding: 20px 187px 20px 187px;
  background-color: var(--white);
  height: calc(100vh - 70px);
  /* margin: 0px 187px; */
  overflow-y: auto;
}

.lottieContainer {
  border-radius: var(--8, 8px);
  border: 1px solid var(--secondary-gray);
  background: var(--white);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 70px 0px;
  position: relative;
}

.close {
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
}
.closeIcon {
  color: var(--primary-gray);
}
.title {
  color: var(--font-dark);
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0.1px;
  margin-bottom: 28px;
}
.cardsContainer {
  display: flex;
  align-items: start;
  justify-content: start;
}
.card {
  width: 30%;
  margin-right: 20px;
}

.cardTitle {
  color: var(--font-dark);
  font-size: 14px;
  font-weight: 500;
}

.cardDescription {
  color: var(--primary-gray);
  font-size: 14px;
  font-weight: 400;
}

.inputsContainer {
  margin-top: 36px;
}

.intputsTitle {
  color: var(--font-dark);
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 22px;
}

.row {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.oneInput {
  width: 48%;
}

.buttonCont {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
  margin-top: 40px;
}

@media (max-width: 1224px) {
  .container {
    height: calc(100vh - 200px);
    margin: 0px;
    padding: 0px 20px;
  }
  .cardsContainer {
    flex-direction: column;
    width: 90%;
  }
  .card {
    width: 100%;
    margin: 0px 0px 30px 0px;
  }
}
