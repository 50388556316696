.container {
  padding: 26px 34px;
}

.header {
  font-size: var(--font-size-20);
  color: var(--font-dark);
  font-weight: 600;
}

.id {
  font-size: var(--font-size-16);
  color: var(--font-dark);
  font-weight: 500;
  margin-top: 20px;
  margin-bottom: 20px;
}

.helper {
  margin-top: 14px;
  color: var(--dark-gray);
  font-size: var(--font-size-16);
}

.textArea {
  border: 1px solid var(--secondary-gray);
  background-color: var(--lighter-gray);
  width: 100%;
  border-radius: 10px;
  padding: 10px;
  font-size: var(--font-size-14);
}
