.loader {
  height: 100%;
  width: 100%;
}

.button {
  width: 100%;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  margin-top: 6px;
  font-weight: 600;
  text-align: center;
  border-width: 2px;
  border-color: var(--secondary-gray);
  color: var(--primary-gray);
  font-size: var(--font-size-14);
}

.buttonOnsidebar {
  color: white;
  background-color: var(--primary-blue);
  height: 44px;
  font-weight: 500;
}

.loaderSpan {
  height: 30px;
  width: 30px;
  position: relative;
}

.mobile {
  height: 60px;
  font-size: var(--font-size-20);
}
