.btn {
    font-family: "Poppins", sans-serif;
    font-size: var(--font-size-16);
    font-weight: 500;
    text-align: center;
    border-radius: 8px;
    padding: 8px 22px;
    cursor: pointer;
}

.primary {
    border: 1px solid var(--primary-blue);
    background-color: var(--primary-blue);
    /* color: var(--lighter-gray); */
    color: white
}

.secondary {
    border: 1px solid var(--primary-gray);
    background-color: var(--white);
    color: var(--primary-gray);
}

.disabled {
    border: 1px solid var(--secondary-gray);
    background-color: var(--secondary-gray);
    color: var(--font-dark);
    cursor: default;
}
.lightPrimary {
    border: 0px solid var(--secondary-gray);
    background-color: #eff5ff;
    color: var(--primary-blue);
}
.alert {
    border: 0px solid var(--secondary-gray);
    background-color: #eb4a4a;
    color: white;
}
