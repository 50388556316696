.container {
    height: 100%;
    width: 100%;
    overflow-y: scroll;
    padding: 12px;
}

.activityHeader {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: var(--font-size-12);
    color: var(--font-dark);
}

.statusBadge {
    width: fit-content;
    min-width: fit-content;
}

.url {
    font-size: var(--font-size-16);
    font-weight: 500;
}

.jsonHeader {
    font-size: var(--font-size-16);
    font-weight: 400;
    margin: 20px 0 12px 0;
    color: var(--primary-gray)
}
