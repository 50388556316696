.container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20;
}

.createButton {
    padding-top: 6px;
    padding-bottom: 6px;
}

.heading {
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 15px;
}

.buttonSection {
    display: flex;
    gap: 10px;
    justify-content: flex-end;
    box-sizing: border-box;
}
