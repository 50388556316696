.itemsContainer {
    font-size: var(--font-size-16);
    height: 20%;
    width: 100%;
    overflow-x: scroll;
    display: flex;
    gap: 8px;
    cursor: pointer;
}

.item {
    padding: 4px 8px;
    border-radius: 6px;
    color: var(--dark-gray);
    background-color: var(--secondary-gray);
    font-style: normal;
    font-weight: 500;
    font-size: var(--font-size-12);
    line-height: 20px;
    text-transform: capitalize;
    text-wrap: nowrap;
}
