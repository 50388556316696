.container {
  font-size: var(--font-size-16);
}
.titles {
  color: var(--primary-gray);
  margin-bottom: 10px;
}
.items {
  color: var(--font-dark);
}
.sharp {
  color: var(--primary-gray);
  margin-right: 3px;
}
