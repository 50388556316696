.container {
    width: 100%;
    height: 60vh;
}

.left {
    width: 35%;
    float: left;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    overflow-y: scroll;
}

.right {
    width: 64%;
    float: right;
    height: 100%;
    border-radius: 8px;
    border: 2px solid var(--secondary-gray);
}

.noteText {
    display: flex;
    align-items: center;
    gap: 10px;
    color: #ff974a;
    margin-top: 20px;
}

.containerRes {
    width: 100%;
}

.leftRes {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    overflow-y: scroll;
}

.rightRes {
    width: 100%;
    max-height: 600px;
    border-radius: 8px;
    border: 2px solid var(--secondary-gray);
    margin-top: 40px;
    overflow-y: scroll;
}
