.disclosureContainer {
  margin-bottom: 0px;
  padding-bottom: 8px;
}

.disclosureTitle {
  cursor: pointer;
  transition: background-color 0.4s ease-in-out;
}

.disclosureContent {
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  transition:
    opacity 0.4s ease-in-out,
    max-height 0.4s ease-in-out;
}

.arrow {
  color: var(--font-dark);
  transition: transform 0.4s ease-in-out;
}

.rotate {
  transform: rotate(180deg);
}

.disclosureContentVisible {
  opacity: 1;
  max-height: 1000px;
  transition:
    opacity 0.4s ease-in-out,
    max-height 0.4s ease-in-out;
}
