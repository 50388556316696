.container {
  width: 200px;
  margin-right: 10px;
  position: relative;
}
.inputWhenOpened {
  border-radius: 8px 8px 0px 0px !important;
}
.containerInput {
  width: 100%;
  border-radius: 8px;
  padding: 8px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--secondary-gray);
  border-width: 1px;
  cursor: pointer;
  color: var(--primary-gray);
}
.dropdownIcon {
  font-size: 16px;
  /* font-size: var(--font-size-16); */
  color: var(--primary-gray);
}
.iconCont {
  display: flex;
  align-items: center;
  font-size: 18px;
}

.placeHolder {
  font-size: var(--font-size-14);
}

.dropdownContainer {
  border-width: 0px 1px 1px 1px;
  width: 100%;
  border-radius: 0px 0px 10px 10px;
  color: var(--primary-gray);
  background-color: var(--white);
  font-size: var(--font-size-14);
  position: absolute;
  z-index: 999;
}

.oneItemInDropdown {
  padding: 10px 20px;
  cursor: pointer;
  text-transform: capitalize;
  width: 100%;
}

.oneItem {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 16px;
}

.oneItem:hover {
  background-color: var(--lighter-gray);
}
