.sectionHeading {
    font-size: var(--font-size-18);
    font-weight: 600;
    line-height: 27px;
    margin-top: 36px;
    margin-bottom: 16px;
    color: var(--font-dark);
}

.cardsContainer {
    display: flex;
    flex-wrap: wrap;
    margin: -8px;
}

.noVehicles {
    color: var(--font-dark);
}
