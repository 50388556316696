.amountCont {
  padding: 14px 14px 15px;
  width: 46%;
}
.paymentCont {
  padding: 20px 14px 15px 30px;
}

.detailsOneLine {
  width: 100%;
}

.noBorder {
  border: 0px;
}

.oneLineTitle {
  font-size: var(--font-size-16);
  text-transform: capitalize;
}
.light {
  color: var(--primary-gray);
}

.dark {
  color: var(--font-dark);
}

.fontBigger {
  font-size: var(--font-size-18);
}
.badge {
  font-weight: 600;
}

.iconClass {
  width: auto;
  height: 100%;
}

.tooltipContainerForIcon {
  width: 269px;
  border-radius: 6px !important;
  border-color: #d9d9d9 !important;
}

.tooltipText {
  color: #92929d;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0.1px;
}
