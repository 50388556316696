.carouselContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.carousel {}

.carousel img {
  flex: 1;
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  object-fit: contain;
}
