.bdg {
  color: #4a72ff;
  font-style: normal;
  font-weight: 500;
  font-size: var(--font-size-14);
  text-transform: capitalize;
  margin-left: 0px;
}

.neutral {
  color: #50B5FF;
}

.success {
  color: #27C687;
}

.warning {
  color: #FF8B33;
}

.error {
  color: #E90505;
}

.primary {
  color: #0062ff;
}

.info {
  background-color: #fff3ea;
  color: #ff8528;
}
