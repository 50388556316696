.container {
  display: flex;
  height: 100vh;
  font-family: Arial, sans-serif;
  padding: 43px 43px 0 43px;
  overflow: hidden;
  gap: 87px
}

.imageSection {
  flex: 1;
  background-color: #0066FF;
  color: white;
  padding: 55px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
  overflow: hidden;
  border-radius: 30px;
  padding-bottom: 12px;
  margin-bottom: 43px;
}

.imageSection h2 {
  margin-bottom: 10px;
  font-size: 40px;
  font-weight: 600;
}

.imageSection p {
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 30px;
}

.deliveryImageContainer {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex: 1;
  width: 100%;
  height: 100%;
}

.deliveryImage {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  border-radius: 10px;
}

.formSection {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow-y: scroll;
  height: 100%;
  max-width: 40%;
}

.formSection h2 {
  font-family: "Poppins";
  font-size: 36px;
  font-weight: 600;
  margin-bottom: 40px;
  color: var(--black);
}

.formSection label {
  font-family: "Poppins";
  margin-bottom: 10px;
  font-size: 15px;
  font-weight: 400;
  color: var(--gray-label);
}

.formSection input {
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.10000000149011612px;
  text-align: left;
  color: var(--primary-gray);
  height: 44px;
  width: 100%;
  border-radius: 8px;
  background-color: var(--secondary-background);
  border: 1px solid var(--table-border-gray);
  padding: 10px;
  margin-bottom: 27px;
  position: relative;
}

.formSection select {
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.10000000149011612px;
  text-align: left;
  color: var(--primary-gray);
  height: 44px;
  width: 100%;
  border-radius: 8px;
  background-color: var(--secondary-background);
  border: 1px solid var(--table-border-gray);
  padding: 10px;
  position: relative;
}

.formSection input:focus,
.formSection select:focus {
  outline: none;
}

.formSection p {
  margin-bottom: 25px;
  color: var(--primary-gray);
}

.formSection button {
  background-color: var(--primary-blue);
  color: white;
  border: none;
  cursor: pointer;
  padding: 10px 24px 10px 24px;
  border-radius: 8px;
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.10000000149011612px;
  margin-top: 13px;
  float: right;
  margin-bottom: 43px;
}

@media screen and (max-width: 1023px) {
  .imageSection {
    display: none;
  }

  .formSection {
    max-width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .container {
    padding: 32px 32px 0 32px;
  }

  .formSection button {
    margin-bottom: 32px;
  }
}
