.container {
  height: 500px;
  overflow-y: hidden;
  padding: 24px;
  border: 1px solid var(--secondary-gray);
  border-radius: 10px;
}

.selectStoreText {
  font-size: var(--font-size-16);
  color: var(--font-dark);
  font-weight: 500;
}
.allStoresText {
  font-size: var(--font-size-16);
  color: var(--primary-gray);
  font-weight: 400;
}

.fontDark {
  color: var(--font-dark);
}

.fontLight {
  color: var(--primary-gray);
}
.primaryColor {
  color: #0062ff;
}

.scrollContainer {
  height: 350px;
  overflow-y: scroll;
}

.form {
  width: 100%;
  border-width: 1px;
  border-radius: 6px;
  border-color: var(--secondary-gray);
  padding: 12px 22px 12px 14px;
  display: flex;
  align-items: center;
  justify-content: start;
  margin-top: 16px;
  margin-bottom: 20px;
}

.searchInput {
  outline: none;
  width: 100%;
  height: 100%;
  margin-left: 10px;
  background-color: transparent;
  color: var(--font-dark);
  font-size: var(--font-size-14);
}
.square {
  color: var(--font-dark);
}
.oneItem:hover {
  background-color: var(--secondary-gray);
}

.storeId {
  font-size: var(--font-size-14);
  color: var(--primary-gray);
  width: 100%;
}
