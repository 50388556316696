.tooltipWrapper {
  position: relative;
  display: inline-block;
}

.tooltip {
  position: absolute;
  padding: 12px;
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  background-color: var(--white);
  visibility: hidden;
  transition: opacity 0.2s ease-in-out;
  z-index: 100;
  height: auto;
}

.tooltipWrapper:hover .tooltip {
  visibility: visible;
  opacity: 1;
}

.tooltipArrow {
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
}

.top {
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: 8px;
}

.top .tooltipArrow {
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-width: 8px 8px 0 8px;
  border-color: #fff transparent transparent transparent;
}

.bottom {
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 8px;
}

.bottom .tooltipArrow {
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-width: 0 8px 8px 8px;
  border-color: transparent transparent #fff transparent;
}

.left {
  right: 100%;
  top: 50%;
  transform: translateY(-50%);
  margin-right: 8px;
}

.left .tooltipArrow {
  left: 100%;
  top: 50%;
  transform: translateY(-50%);
  border-width: 8px 0 8px 8px;
  border-color: transparent transparent transparent #fff;
}

.right {
  left: 100%;
  top: 50%;
  transform: translateY(-50%);
  margin-left: 8px;
}

.right .tooltipArrow {
  right: 100%;
  top: 50%;
  transform: translateY(-50%);
  border-width: 8px 8px 8px 0;
  border-color: transparent #fff transparent transparent;
}

.tooltipContent {
  text-align: left;
}

.heading {
  color: var(--gray-heading);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
}

.description {
  font-size: 12px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  color: var(--gray-sub-heading);
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
  visibility: hidden;
}
