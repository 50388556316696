.circle {
  width: 18px;
  height: 18px;
  border-radius: 20px;
  border: 1.5px solid var(--light-gray);
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.smallCircle {
  width: 10px;
  height: 10px;
  border-radius: 12px;
  /* background-color: var(--light-gray); */
}

.activeBgColor {
  background-color: var(--primary-blue) !important;
}
.activeBorderColor {
  border-color: var(--primary-blue) !important;
}

.reason {
  color: var(--dark-gray);
}
