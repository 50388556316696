.container {
    width: 100%;
    overflow: auto;
    border: 1px solid var(--secondary-gray);
    border-radius: 4px;
    padding: 18px;
    background-color: var(--lighter-gray);
    /* background-color: red; */
}

.textStyle {
    font-size: var(--font-size-14);
    line-height: 1.4;
}
