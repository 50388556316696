.buttonTextSize {
  font-size: var(--font-size-14);
  padding: 8px 10px;
  font-weight: 400;
}

.badge {
  font-size: var(--font-size-14);
  padding: 10px 8px;
  white-space: nowrap;
}

.statusClass {
  width: auto;
  height: 100%;
}
