.container {
  position: relative;
  margin-right: 16px;
}

.buttonContainer {
  border: 1px solid var(--secondary-gray);
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 15px 10px 10px;
  font-size: 13px;
  color: var(--primary-gray);
  cursor: pointer;
  text-wrap: nowrap;
}
.iconClasses {
  fill: var(--primary-blue);
}

.categorie {
  display: flex;
  border-left: 1px solid var(--secondary-gray);
  margin-left: 6px;
  padding-left: 6px;
  align-items: center;
  color: #0062ff;
}
.count {
  height: 18px;
  width: 24px;
  border-radius: 20px;
  background-color: #0062ff;
  text-align: center;
  margin-right: 4px;
  color: white;
  font-size: 12px;
}
.catName {
  color: #0062ff;
  text-transform: capitalize;
  max-width: 80px;
  width: 80px;
  overflow: hidden;
}
.filterDropdown {
  position: absolute;
  top: 44px;
  left: 0px;
  width: 200px;
  background-color: var(--white);
  border-radius: 6px;
  border: 1px solid var(--secondary-gray);
  box-shadow: -1px 39px 112px -13px rgba(0, 0, 0, 0.3);
  padding: 12px 12px;
  width: 300px;
  z-index: 999;
  max-height: 400px;
  overflow-y: auto;
}

.dropdownTitle {
  border-bottom: 1px solid var(--secondary-gray);
  padding-bottom: 12px;
  padding-left: 20px;
  display: flex;
  align-items: center;
  font-size: 13px;
  color: var(--primary-gray);
  font-weight: 500;
}

.searchFormCont {
  width: 100%;
  border: 1px solid var(--secondary-gray);
  border-radius: 6px;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  justify-content: start;
  background-color: var(--bg-settings);
  font-size: 14px;
}

.searchInput {
  outline: none;
  width: 100%;
  height: 100%;
  margin-left: 10px;
  background-color: transparent;
  color: var(--primary-gray);
}

.sotoreItem {
  /* height: 50px; */
  border-radius: 8px;
  padding: 6px 6px;
  margin: 4px 0px;
  width: 100%;
  color: var(--primary-gray);
}

.sotoreItem:hover {
  background-color: var(--bg-settings);
}
.storeName {
  color: var(--primary-gray);
  width: 90%;
  font-size: 14px;
  font-weight: 400;
}
