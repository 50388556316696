.footer {
    display: flex;
    align-items: center;
    padding-left: 10px;
    gap: 10px;
    width: 100%;
    height: 63px;
    background: var(--bg-settings);
    font-size: var(--font-size-12);
    color: var(--primary-gray);
    cursor: pointer;
}

.fontSize12 {
    font-size: 12px;
}
