.trackRerouteDelivery {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--white-blue);
    border-radius: 10px;
    padding: 14px 20px;
    margin-bottom: 24px;
    font-size: var(--font-size-16);
}

.trackLink {
    color: var(--primary-blue);
    border-radius: 10px;
    font-weight: 600;
    text-decoration: underline;
}

.trackRerouteText {
    display: flex;
    align-items: center;
    gap: 10px;
    color: var(--font-dark);
}
