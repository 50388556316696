.input-container {
    /* border: 1px solid gray;
    border-radius: 4px;
    width: 250px;
    
    height: 2rem;
    position: relative; */
}

.input-container textarea {
    border: none;
    position: absolute;
    inset: 0;
    z-index: 1;
    outline: none;
    padding: 11px 9px 0px 12px;
    background: transparent;
    text-align: start;
    top: 0px;
    bottom: 0px;
}
/* For showing through placeholder */
.input-container textarea:not([value=""]) {
    -webkit-text-fill-color: transparent;
}

.input-container .input-renderer {
    /* width: 100%;
position: absolute;
inset: 0;
margin: 0 0.5rem;
user-select: none;
scrollbar-width: none;
background-color: red; */
}

.input-container .input-renderer::-webkit-scrollbar {
    display: none;
}

.highlighted {
    background-color: #0062ff;
    border-radius: 6px;
    color: white;
    padding: 4px 0px;
}

* {
    box-sizing: border-box;
}
