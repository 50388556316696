.container {
  background-color: var(--white);
  width: 100%;
  padding: 30px 46px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 8px;
}

.title {
  font-size: var(--font-size-16);
  font-weight: 600;
  color: var(--font-dark);
}

.textContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.buttonTitle {
  font-weight: 600;
  font-size: var(--font-size-16);
  color: #4d89da;
}

.buttonText {
  font-weight: 400;
  font-size: var(--font-size-16);
  color: var(--primary-gray);
  text-align: start;
}
