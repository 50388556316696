.container {
  background-color: var(--secondary-background);
  border: 1px solid var(--lighter-gray);
  padding-left: 10px;
  border-radius: 20px;
}
.dropdownContainer {
  border-right: 1px solid var(--light-gray);
  display: flex;
  align-items: center;
  height: 40px;
}
