.container {
    height: 100%;
    overflow-y: scroll;
    cursor: pointer;
}

.heading {
    font-weight: 500;
    font-size: 18px;
    color: #171725;
    display: flex;
    align-items: center;
}

.listContainer {
    width: 100%;
    margin-top: 24px;
    padding: 24px 16px;
    border-radius: 10px;
    font-family: Poppins;
    position: relative;
}
