.container {
  background-color: var(--white);
  width: 100%;
  padding: 30px 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 8px;
}

.title {
  font-size: var(--font-size-16);
  font-weight: 600;
  color: var(--font-dark);
}

.cardContainer {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.card {
  display: flex;
  flex-direction: column;
  background-color: var(--lighter-gray);
  border-radius: 8px;
  justify-content: start;
  align-items: center;
  outline: none;
}

.cardTitle {
  margin-top: 10px;
  color: #4d89da;
  font-size: var(--font-size-16);
  font-weight: 600;
  margin-bottom: 12px;
}

.cardText {
  text-align: center;
  font-size: var(--font-size-14);
  color: var(--primary-gray);
}
