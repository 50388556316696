@keyframes float {
  0% {
    transform: translatey(5px);
 }
  50% {
    transform: translatey(-5px);
 }
  100% {
    transform: translatey(5px);
 }
}
@keyframes popInRight {
  0% {
    transform: scale(0) translatex(0px);
 }
  100% {
    transform: scale(1) translatex(-30px);
    opacity: 100%;
 }
}
@keyframes popInLeft {
  0% {
    transform: scale(0) translatex(0px);
 }
  100% {
    transform: scale(1) translatex(30px);
    opacity: 100%;
 }
}
@keyframes pulse {
  0% {
    transform: scale(1);
 }
  50% {
    transform: scale(1.2);
 }
  100% {
    transform: scale(1);
 }
}
.illustration__container {
  width: 160px;
  height: 160px;
  background-color: #f0f8ff;
  box-sizing: border-box;
  border-radius: 20px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.illustration__container:hover .illustration__containerBackground svg {
  animation: pulse 4s ease-in-out infinite;
}
.illustration__containerBackground {
  width: 160px;
  height: 160px;
  position: absolute;
  overflow: hidden;
  border-radius: 20px;
}
.illustration__elements {
  animation: float 6s ease-in-out infinite;
  background-color: none;
  animation-delay: 2s;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transform: translatey(5px);
}
.illustration__element {
  margin-bottom: 15px;
  background-color: #fff;
  width: 140px;
  border-radius: 6px;
  transform: scale(O);
  box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.2);
  padding: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.illustration__element--right.illustration__element--1 {
  transform: translatex(-30px);
  animation-fill-mode: forwards;
  transform-origin: top left;
}
.illustration__element--right.illustration__element--3 {
  transform: translatex(-30px);
  animation-fill-mode: forwards;
  transform-origin: top left;
  margin: 0;
}
.illustration__element--left {
  transform: translatex(30px);
  animation-fill-mode: forwards;
  transform-origin: top right;
}
.illustration__elementType {
  width: 24px;
  height: 24px;
  background-color: #339dff;
  border-radius: 6px;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  margin-right: 10px;
}
.illustration__elementWireframe {
  width: 100px;
  height: 8px;
  background-color: #ddd;
  border-radius: 6px;
}
