.rerouteReasonContainer {
    width: 70%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
}

.container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 60px;
    min-height: 300px;
    padding: 40px;
}

.button {
    width: fit-content;
}

.loader {
    height: 24px;
}
