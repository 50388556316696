.bdg {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
  gap: 10px;
  background-color: #f2f5ff;
  color: #4a72ff;
  border-radius: 50px;
  font-style: normal;
  font-weight: 500;
  font-size: var(--font-size-12);
}

.bdg .iconDefault {
  background-color: #4a72ff;
  height: 100%;
  width: 100%;
  border-radius: 100%;
}

.neutral {
  background-color: #f1f4fa;
  color: var(--primary-gray);
}

.neutral .iconDefault {
  color: var(--primary-gray);
}

.success {
  background-color: #eafaf4;
  color: #3dd598;
}

.success .iconDefault {
  background-color: #3dd598;
}

.warning {
  background-color: #fff1e5;
  color: #ff974a;
}

.warning .iconDefault {
  background-color: #ff974a;
}

.error {
  background-color: #ffe6e6;
  color: #fc5a5a;
}

.error .iconDefault {
  background-color: #fc5a5a;
}

.primary {
  background-color: #f4f8ff;
  color: #0062ff;
}

.primaryWithUnderLine {
  background-color: #f4f8ff;
  color: #0062ff;
  text-decoration: underline;
}

.primary .iconDefault {
  background-color: #0062ff;
}

.pickup {
  background-color: #e5f4ff;
  color: #50b5ff;
}

.pickup .iconDefault {
  background-color: #50b5ff;
}

.dropoff {
  background-color: #f3ebfa;
  color: #a461d8;
}

.dropoff .iconDefault {
  background-color: #a461d8;
}

.info {
  background-color: #fff3ea;
  color: #ff8528;
}

.info .iconDefault {
  background-color: #ff8528;
}

.iconContainer {
  width: 8px;
  min-width: 8px;
  height: 8px;
}

.fontSize {
  font-size: var(--font-size-10);
}

.uppercase {
  text-transform: uppercase;
}

.caution {
  background-color: #FFF9E8;
  color: #FFD15B;
}

.caution .iconDefault {
  background-color: #FFD15B;
}

.progress {
  background-color: #E5F4FF;
  color: #50B5FF;
}

.progress .iconDefault {
  background-color: #50B5FF;
}

.reroutedBadge {
  background-color: var(--brand-blue-100);
  color: var(--brand-blue-800);
}

.attemptRerouteBadge {
  color: #6B6B6B;
  background-color: #F6F6F6;
}