.expandedContainer {
  display: flex;
  flex-direction: column;
  margin: 4px 0px 4px 0px;
  padding: 4px 0px 4px 55px;
  cursor: pointer;
  width: 100%;
  /* align-items: end; */
}
.smallerCont {
  padding: 4px 0px 4px 16px;
  width: auto;
}
.hoverWidth:hover {
  width: 56px;
}

.buttonTitle:hover {
  background-color: var(--secondary-gray);
  padding: 6px 14px;
  margin: 2px 0px;
  border-radius: 10px;
  color: var(--font-dark);
  /* padding: 4px 0px 4px 16px; */
}

.buttonTitle {
  display: flex;
  align-items: center;
  padding: 4px 0px 4px 14px;
  color: var(--primary-gray);
  justify-content: space-between;
  margin: 4px 16px 4px 0px;
}
.smallTextCont {
  padding: 4px !important;
  margin-left: -4px;
}
.active {
  color: var(--primary-blue) !important;
  fill: var(--primary-blue) !important;
  background-color: var(--secondary-gray) !important;
  border-radius: 10px;
  padding: 6px 14px;
  margin: 2px 0px;
}
