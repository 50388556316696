.avatarFallback {
  background: #9bd062 !important;
}

.avatarFallback span {
  font-size: 14px !important;
  font-weight: 500;
}

.menuItem {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  padding: 6px 8px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.menuItem:hover {
  background-color: var(--menu-item-hover);
}

.menuItemIcon {
  color: var(--menu-icon);
  margin-right: 8px;
}

.menuItemText {
  color: var(--menu-text);
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  padding-left: 0;
  padding-right: 0;
}

.menuFooter {
  border-color: var(--menu-divider-line);
  padding: 5px;
  border-top-width: 1px;
}

.menuHeader {
  border-color: var(--menu-divider-line);
  padding: 5px;
  border-bottom-width: 1px;
}

.menuHeaderText {
  padding-right: 0;
  padding-left: 0;
  white-space: nowrap;
  color: var(--menu-header-text);
  line-height: 20px;
  margin-left: 8px;
  font-size: 14px;
  font-weight: 600;
}
