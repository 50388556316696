@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");
.container {
    display: flex;
    width: 100vw;
    height: 100vh;
    justify-content: center;
    align-items: center;
}
.contentContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1280px;
    max-height: 92%;
    height: 100%;
    width: 100%;
    justify-content: space-between;
    background-color: white;
    overflow-y: auto;
    border-radius: 10px;
    box-shadow: 0px 6px 30px 0px rgba(0, 0, 0, 0.25);
}
.background {
    background-image: url("../../../assets/png/onboarding/screenshot.png");
    background-size: cover;
    background-position: center;
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    /* filter: blur(10px); */
    /* display: flex;
    flex-direction: column;
    align-items: center; */
}

.hyperLink {
    color: var(--primary-blue);
    margin-left: 6px;
    margin-right: 2px;
}

.title {
    color: #222;
    text-align: center;
    font-family: Inter;
    font-size: 40px;
    font-weight: 500;
    margin-top: 40px;
    font-family: "Inter", sans-serif;
}

.instructions {
    list-style-type: disc;
    padding-left: 20px;
    margin-bottom: 20px;
    font-family: "Inter", sans-serif;
}

.instructions li {
    color: #424242;
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
}

.inputCont {
    width: 500px;
}

.inputs {
    height: 42px;
    background-color: white;
    width: 100%;
    border-radius: 10px;
    border: 1px solid #e2e2e2;
    margin-top: 6px;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 16px;
    font-weight: 400;
    color: #171725;
}
.label {
    color: #222;
    font-family: "Inter", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.buttonStyle {
    margin-top: 30px;
    width: 100%;
    cursor: pointer;
}
.buttonStyleDisabled {
    margin-top: 30px;
    width: 100%;
    cursor: default;
}

@media (max-width: 1023px) {
    .inputCont {
        width: 370px;
    }
    .contentContainer {
        max-height: 100%;
    }
}

@media (max-width: 1323px) {
    .contentContainer {
        max-height: 100%;
    }
}
@media (min-width: 3840px) {
    .contentContainer {
        max-width: 2560px;
        max-height: 1440px;
    }
}
