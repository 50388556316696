.title {
  font-weight: 500;
  font-size: var(--font-size-20);
  color: var(--font-dark);
  border-bottom: solid 1px var(--secondary-gray);
  padding: 20px;
}

.textAreaCont {
  padding: 0px 20px;
  margin-top: 10px;
}

.textArea {
  border: 1px solid var(--secondary-gray);
  /* box-shadow: 0px 20px 50px 0px rgba(220, 224, 249, 0.5); */
  /* box-shadow: 0px 20px 50px 0px var(--sky-blue); */
  box-shadow: 0px 20px 50px 0px var(--white-blue);
  width: 100%;
  border-radius: 10px;
  padding: 10px;
  font-size: var(--font-size-14);
  background-color: var(--white);
  color: var(--font-dark);
}

.helperText {
  color: var(--primary-gray);
  display: flex;
  align-items: start;
  font-size: var(--font-size-14);
}

.textArea:focus {
  outline: none;
}

.textTitle {
  color: var(--font-dark);
  font-weight: 500;
}
.saveButton {
  width: 80%;
  padding: 7px;
  font-weight: 400;
  border-radius: 7px;
  color: white;
}
