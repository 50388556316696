.container {
  width: 100%;
  padding-left: 100px;
  margin-top: 10px;
}

.checkboxContainer {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.checkboxLabel {
  font-size: var(--font-size-14);
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: var(--font-dark);
  cursor: pointer;
}

.checkboxInput {
  margin-right: 8px;
  cursor: pointer;
}

.tooltipContainer {
  width: 269px;
  border-radius: 6px !important;
  border-color: #d9d9d9 !important;
}

.tooltipText {
  color: #92929d;
  font-family: Roboto;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.1px;
}

.inputLabel {
  font-size: var(--font-size-14);
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: var(--font-dark);
  width: fit-content;
}

.fieldContainer {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 18px;
  position: relative;
}

.inputField {
  font-family: Poppins;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0.10000000149011612px;
  text-align: right;
  padding: 0 10px;
  color: var(--black);
  height: 27px;
  width: 120px;
  border-radius: 8px;
  border: 1px solid var(--secondary-gray);
  background-color: var(--secondary-gray);
  position: relative;
}

.inputField:focus {
  outline: none;
}

.errorStyle {
  color: var(--red-dark);
  font-size: var(--font-size-12);
  padding: 6px 10px 0px 12px;
  position: absolute;
  top: 20px;
  left: 96px;
}
