.listContainer {
  background-color: var(--white);
  padding: 0px 20px;
  /* overflow: hidden; */
  border-color: var(--secondary-gray);
}

.listItemContainer {
  height: 94px;
  width: 100%;
  flex-direction: row;
  align-items: center;
  display: flex;
  outline: none;
  justify-content: space-between;
  border-color: var(--secondary-gray);
}

.badgeExtraStyles {
  width: 150px;
  font-size: 14px;
  font-weight: 500;
}

.textPrimaryBlue {
  color: var(--primary-blue);
}

.textGrey {
  color: var(--primary-gray);
}

.directionNameCont {
  font-size: var(--font-size-14);
  line-height: 18px;
  text-align: left;
}

.dropoffAddressAndDriverTexts {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: var(--font-size-12);
}

.badgesAndinfoContainer {
  display: flex;
  flex-direction: row;
  padding-right: 34px;
  justify-content: space-between;
  min-width: 450px;
  height: 100%;
  align-items: center;
}

.statusLogos {
  width: 54px;
  height: 54px;
}

.borderTop {
  border-top-width: 0.5px;
}

.badge {
  font-size: var(--font-size-14);
  padding: 3px 10px;
  white-space: nowrap;
}

.statusClass {
  width: auto;
  height: 100%;
}

.viewLink {
  color: var(--dark-gray);
  margin-left: 18px;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  text-align: left;
}

.mobileStatusLogos {
  width: 100px; 
  height: 100px; 
  margin-top: 25px;
}
