.rowContainer {
    display: flex;
    align-items: center;
    border-radius: 8px;
    padding: 12px;
    background-color: transparent;
}

.evenRowContainer {
    background-color: #F2F2F2;
}

.rowText {
    font-size: 14px;
    display: flex;
    font-weight: 500;
    width: 60%;
    justify-content: start;
    margin-left: 10px;
}

.logo {
    height: 100%;
}

.logoContainer {
    height: 30px;
    width: 30px;
    border-radius: 100px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px 10px;
    background-color: #F2F2F2;
}

.logoContainerEven {
    background-color: #ffffff;
}

.businessNameContainer {
    display: flex;
    align-items: center;
    width: 40%;
}
