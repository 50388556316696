.title {
  display: flex;
  font-weight: 400;
  font-size: var(--font-size-16);
  color: var(--dark-gray);
  width: 100%;
  background-color: var(--white-blue);
  height: 31px;
  align-items: center;
  border-radius: 5px 5px 0px 0px;
  padding-left: 12px;
}

.reasonCont {
  width: 100%;
  max-height: 530px;
  min-height: 70px;
  overflow-y: scroll;
  padding: 14px;
  background-color: var(--sky-blue);
  color: var(--dark-gray);
}
