.card {
  border-radius: 18px;
  border: 1px solid var(--secondary-gray);
  margin-top: 24px;
}

.icon {
  color: var(--font-dark);
  /* color: var(--primary-gray); */
}

.header {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  color: var(--font-dark);
}

.reviewStatement {
  flex-grow: 1;
  font-weight: 500;
  margin-left: 20px;
  font-size: var(--font-size-16);
}

.badgeExtraStyles {
  font-size: var(--font-size-14);
  font-weight: 500;
  padding: 5px 18px;
}

.content .row {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  color: var(--font-dark);
}

.label {
  color: var(--font-dark);
  font-size: 16px;
}

.value {
  font-size: 16px;
  color: var(--font-dark);
}
.reasonCont {
  padding: 14px 22px;
  border-bottom: 1px solid var(--secondary-gray);
  color: var(--font-dark);
}

.borderBottom {
  border-bottom: 1px solid var(--secondary-gray);
}

.cancelIcon {
  color: var(--font-dark);
}
.cancelationTitle {
  font-size: var(--font-size-16);
  color: var(--font-dark);
  font-weight: 500;
  margin-left: 20px;
}
