.label {
    font-size: var(--font-size-16);
    margin-bottom: 10px;
    font-weight: 600;
    display: flex;
    align-items: center;
    color: var(--font-dark);
}

.createdDate {
    color: var(--primary-gray);

}
.created{
    color: var(--font-dark);
    font-size: var(--font-size-16);
}