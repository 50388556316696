.kata::-webkit-scrollbar {
  display: none;
}

.kata {
  overflow-y: scroll;
}

.outineNon:focus {
  outline: none;
}
