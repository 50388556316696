.container {
  display: flex;
  flex-direction: column;
  width: calc(100% - 180px);
  margin-left: 150px;
  margin-top: 20px;
  background-color: var(--sky-blue);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 10px 10px 10px 10px;
  padding: 20px 20px 0px 20px;
}

.headerCont {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.itemHeaderText {
  width: 25%;
  font-weight: 500;
  font-size: var(--font-size-16);
  line-height: 18px;
  color: var(--primary-gray);
}

.bottomCont {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 10px;
}

.bottomContTexts {
  font-size: var(--font-size-16);
  color: var(--font-dark);
  font-weight: "500";
}
