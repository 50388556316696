.markers {
  margin-top: -35px;
  margin-left: -25px;
  height: 50px;
  width: 50px;
}

.pickupTootlip {
  top: -80px;
  padding-bottom: 45px;
  left: -50%;
  min-width: 200px;
  z-index: 999;
}

.tooltipTitle {
  color: #92929d;
  font-size: var(--font-size-12);
}

.tooltipBody {
  color: black;
  font-size: var(--font-size-14);
}

.vehicles {
  margin-top: -35px;
  margin-left: -25px;
  height: 55px;
  width: 45px;
}

.courierImageOnMap {
  height: 55px;
  width: 55px;
  border-radius: 100px;
  overflow: hidden;
  z-index: 999;
}

.circle {
  border-radius: 100px;
  top: 0px;
  z-index: 999;
  opacity: 0.8;
}

.maskingColor {
  background-color: #e8e8e8;
}

.circleResp {
  margin-left: -25px;
  margin-top: -35px;
  width: 50px;
  height: 50px;
}
.dropoffBigCircleColor {
  background-color: #deebff;
}

@media (max-width: 1223px) {
  .markers {
    height: 100px;
    width: 100px;
  }
  .vehicles {
    height: 105px;
    width: 95px;
  }
  .circleResp {
    margin-left: -45px;
    margin-top: -45px;
    width: 90px;
    height: 90px;
  }
}
