/* src/GitHubErrorPage.module.css */
.container {
  text-align: center;
  height: 100vh;
  overflow-y: auto;
  padding-bottom: 30px;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  align-items: center;
}

.octocat {
  width: 140px;
  height: 140px;
  margin-top: 40px;
  object-fit: contain;
}

.errorImage {
  width: 300px;
  height: 300px;
  margin-top: 130px;
  object-fit: contain;
}

.err500 {
  font-size: 24px;
  font-weight: 500;
  color: #000000;
  padding: 0 24%;
}

.title {
  font-size: 16px;
  margin-top: 12px;
  color: #92929d;
  padding: 0 24%;
}

.link {
  margin: 5px 0;
  color: #0062ff;
  text-decoration: none;
}

.link:hover {
  text-decoration: underline;
}
