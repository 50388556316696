@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");

.container {
  width: 50vw;
  max-width: 90%;
  margin-top: 0px;
}

.title {
  color: #222;
  text-align: center;
  font-family: "Inter", sans-serif !important;
  font-size: 40px;
  font-weight: 500;
  margin-bottom: 6px;
}
.helperText {
  color: #424242;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  font-family: "Inter", sans-serif !important;
}

.formContainer {
  width: 80%;
  margin-top: 30px;
}

.inputs {
  height: 38px;
  background-color: white;
  width: 100%;
  border-radius: 10px;
  border: 1px solid #e2e2e2;
  margin-right: 32px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
}
.inputLable {
  color: #000;
  font-family: "Inter", sans-serif !important;
  font-size: 12px;
  font-weight: 400;
}

.validationTexts {
  display: flex;
  align-items: center;
  color: #92929d;
  font-family: "Inter", sans-serif !important;
  font-size: 12px;
  font-weight: 400;
}

.passInput {
  width: 100%;
  height: 100%;
  outline: none;
  font-family: "Inter", sans-serif !important;
  font-size: 14px;
  font-weight: 400;
  color: #000;
}
.faqText {
  font-size: 14px;
  font-family: "Inter", sans-serif !important;
  color: #929292;
  margin-left: 4px;
}

@media (max-width: 1023px) {
  .container {
    width: 90vw;
  }
}
