.container {
  padding: 24px 36px;
  background-color: var(--white);
  height: 100%;
}

.title {
  font-size: var(--font-size-18);
  font-weight: 600;
  color: var(--font-dark);
}
.required {
  font-size: var(--font-size-16);
  color: var(--red-dark);
  margin-left: 1px;
  margin-bottom: 5px;
}

.reqText {
  font-size: var(--font-size-14) !important;
}

.halfWidthInput {
  width: 46%;
}

.cardTitle {
  font-weight: 500;
  color: var(--font-dark);
  display: flex;
}
.card {
  border: 1px solid var(--secondary-gray);
  margin: 0px 0px 24px 0px;
  padding: 20px;
}
.card:hover {
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.cardActive {
  border: 1px solid var(--primary-blue);
}

.card:focus {
  border: 1px solid var(--primary-blue);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.cardText {
  color: var(--primary-gray);
}
.button {
  color: white !important;
  font-weight: 400;
  font-size: var(--font-size-18);
}

.disabled {
  opacity: 0.6;
}

.disabledInput{
    opacity: 0.6;
    cursor: not-allowed;
}