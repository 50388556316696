.expressionTopContainer {
    width: 40%;
}

.expressionContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.expressionLeftContainer {
    width: 40%;
}

.expressionOperatorContainer {
    width: 20%;
    margin: 0 20px;
}

.expressionRightContainer {
    width: 40%;
}
