.badgeWrapper {
  width: 100%;
  display: flex;
}
.badge {
  font-size: 14px;
  padding: 4px 8px;
  width: fit-content;
  height: fit-content;
}

.tableRowText {
  border-bottom-width: 1px;
  border-bottom-style: solid;
  text-align: left;
  font-size: 14px;
  font-weight: 500;
  color: var(--primary-gray);
  padding-left: 1rem;
  cursor: pointer;
}

.tableRow {
  height: 50px;
  display: table;
  width: 100%;
  table-layout: fixed;
}

.tableRow:hover {
  background-color: var(--secondary-gray);
}

