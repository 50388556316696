.input {
    font-size: 14px;
    border: 0px;
    padding: 3px 10px;
    border-radius: 8px;
    outline-width: 0px;
    width: 100%;
    font-weight: 400;
}

.inputLable {
    font-weight: 500;
    color: var(--font-dark);
    font-size: var(--font-size-18);
    margin-top: 30px;
    margin-bottom: 6px;
    margin-left: 4px;
}

.inputForEdit {
    width: 100%;
    border-width: 1px;
    border: solid var(--secondary-gray);
    padding: 6px 4px;
    border-radius: 10px;
    display: flex;
}

.imagesCont {
    height: 90px;
    width: 90px;
    border-radius: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--warning-grey);
    border: 4px solid var(--white);
    margin-left: -20px;
    z-index: 200;
}

.burqLogo {
    height: 70px;
    width: 70px;
    border-radius: 50px;
    overflow: hidden;
}

.providerLogo {
    height: 100%;
    width: 100%;
    border-radius: 50px;
    overflow: hidden;
    object-fit: cover;
}

.loaderContainer {
    height: 70vh;
}

.warningMessage {
    font-size: var(--font-size-12);
    color: var(--primary-gray);
    display: flex;
    align-items: flex-start;
    width: 100%;
    margin-top: 16px;
}

.copyTextContainer {
  margin: 0 !important;
  padding: 0 !important;
}

.tooltipAdjustmentClass {
    top: -20px !important;
    left: 80% !important;
}