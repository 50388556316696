.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0px;
}
.buttons {
  border-color: var(--secondary-gray);
  border-radius: 4px;
  border-width: 1px;
  padding: 4px 12px;
  outline: none;
  margin-left: 10px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}
