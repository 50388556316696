.columnTitle {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  text-align: left;
  font-size: 14px;
  font-weight: 500;
  color: var(--dark-gray);
}

.center {
  padding-left: 4rem;
}

.columnLastTitle {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  text-align: right;
  font-size: 14px;
  font-weight: 500;
  color: var(--dark-gray);
  padding-right: 39px;
}
