.container {
  position: relative;
}

.line {
  position: absolute;
  width: 1px;
  background-color: var(--secondary-gray);
  top: 0px;
  bottom: -55px;
  left: -26px;
}
.iconCont {
  position: absolute;
  height: 30px;
  width: 30px;
  background-color: var(--white);
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  left: -40px;
  top: 0;
  z-index: 99;
}

.icon {
  color: var(--primary-gray);
  font-size: var(--font-size-20);
}
.ids {
  width: 49%;
  color: var(--primary-gray);
  font-size: var(--font-size-16);
  font-weight: 400;
}

.addressHalf {
  width: 58%;
}
.namePhoneHalf {
  width: 37%;
}

.fontDark {
  color: var(--font-dark);
  display: flex;
  align-items: center;
}

.notes {
  color: var(--primary-gray);
}

.timesCont {
  width: 20%;
  color: var(--primary-gray);
  font-size: var(--font-size-14);
}

.detailsCont {
  width: 80%;
}
