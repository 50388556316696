.badgeWrapper {
  width: 100%;
  display: flex;
  /* justify-content: start; */
  padding-left: 3rem;
}
.badge {
  font-size: 14px;
  padding: 4px 8px;
  width: fit-content;
  height: fit-content;
}

.tableRowText {
  border-bottom-width: 1px;
  border-bottom-style: solid;
  text-align: left;
  font-size: 14px;
  font-weight: 500;
  color: var(--primary-gray);
  padding-left: 1rem;
}

.tableRow {
  min-height: 60px;
  display: table;
  width: 100%;
  table-layout: fixed;
}

.tableRow:hover {
  background-color: var(--secondary-gray);
}

.cardNumberContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 31px;
}
.tableLastColumn {
  border-bottom-width: 1px;
  border-bottom-style: solid;
  align-self: right;
  font-size: 14px;
  font-weight: 500;
  color: var(--primary-gray);
  display: flex;
  height: 60px;
  align-items: center;
  justify-content: end;
  padding-right: 39px;
}

.settingIcon {
  cursor: pointer;
}

.tooltipContainerForUnverified {
  width: 269px;
  border-radius: 6px !important;
  border-color: #d9d9d9 !important;
}

.tooltipContainerForLinkExpired {
  width: 332px;
  border-radius: 6px !important;
  border-color: #d9d9d9 !important;
}

.tooltipText {
  color: #92929d;
  font-family: Roboto;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 163.636% */
  letter-spacing: 0.1px;
}
