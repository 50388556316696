* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: sans-serif;
}

header {
  box-shadow:
    0 1px 3px 0 rgba(0, 0, 0, 0.07),
    0 1px 2px 0 rgba(0, 0, 0, 0.05);
  color: #212529;
}

.nav-area {
  display: flex;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 10px 20px;
}

.logo {
  text-decoration: none;
  font-size: 25px;
  color: inherit;
  margin-right: 20px;
}

.menus {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  list-style: none;
}

.menu-items {
  position: relative;
  /* font-size: 14px; */
}

.buttonContainer {
  border: 1px solid var(--secondary-gray) !important;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7px 15px 7px 7px !important;
  font-size: 13px !important;
  color: var(--primary-gray);
  cursor: pointer;
  text-wrap: nowrap;
}

.titleCont {
  font-size: 14px;
  color: var(--primary-gray);
  display: flex;
  align-items: center;
  padding: 2px 0px 2px 0px;
  text-wrap: nowrap;
}

.menu-items a {
  display: block;
  font-size: inherit;
  color: inherit;
  text-decoration: none;
}

.menu-items button {
  display: flex;
  align-items: center;
  color: inherit;
  font-size: inherit;
  border: none;
  background-color: transparent;
  color: #92929d;
  cursor: pointer;
  width: 100%;
}

button span {
  margin-left: 3px;
}

.menu-items > a,
.menu-items button {
  text-align: left;
  /* padding: 0 1rem; */
}

.menu-items a:hover,
.menu-items button:hover {
  color: #0062ff;
}

.item-active button {
  color: #0062ff;
}

.arrow::after {
  content: "";
  display: inline-block;
  margin-left: 0.28em;
  vertical-align: 0.09em;
  border-top: 0.42em solid;
  border-right: 0.32em solid transparent;
  border-left: 0.32em solid transparent;
}

.dropdown {
  position: absolute;
  /* right: 0; */
  left: auto;
  box-shadow:
    0 10px 15px -3px var(--shadowColor),
    0 4px 6px -2px var(--shadowColor);
  font-size: 0.875rem;
  z-index: 9999;
  width: max-content;
  padding: 0.5rem 0;
  list-style: none;
  background-color: var(--white);
  border-radius: 0.5rem;
  display: none;
}

.dropdown.show {
  display: block;
}

.dropdown .dropdown-submenu {
  position: absolute;
  left: 100%;
  top: -7px;
}

/* content */

.content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 3rem 20px;
}

.content h2 {
  margin-bottom: 1rem;
}

.content a {
  color: #cc3852;
  margin-right: 10px;
}
